/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useAuthStore, useLoadingStore } from "../stores/hooks";

const CONTRACTS = {
  CONSIGNMENT_AGREEMENT: "consignmentAgreement",
  PERSONAL_INFORMATION_SECURITY_PLEDGE: "personalInformationSecurityPledge",
  PERSONAL_INFOMATION_CONSENT_FORM: "personalInformationConsentForm",
};

const ContractViewer = ({ type, onClose }) => {
  const handleClickSignature = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ contractType: type }),
      );
    }
  };

  return (
    <div className="p-10">
      <img src={`/images/contracts/${type}.png`} alt="" className="w-full" />
      <div className="grid gap-10 mt-10">
        <button
          type="button"
          className="bg-orange text-white w-full p-10"
          onClick={handleClickSignature}
        >
          서명하기
        </button>
        <button type="button" className="w-full p-10" onClick={onClose}>
          취소
        </button>
      </div>
    </div>
  );
};

const Button = ({ text, type, active, force, onClick }) => {
  const handleClick = () => {
    if (force || !active) {
      onClick(type);
    }
  };

  return (
    <button
      type="button"
      className={`border-1 bg-white ${
        active ? "border-orange text-orange" : "border-gray-a  text-gray-a"
      } w-full p-10`}
      onClick={handleClick}
    >
      {text}({active ? "완료" : "미완료"})
    </button>
  );
};

function SettingContract() {
  const { user } = useAuthStore().state;
  const { ...actions } = useAuthStore();

  const { ...loadingActions } = useLoadingStore();

  const [contractType, setContractType] = useState(null);

  useEffect(() => {
    fetchMe();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener("message", receiveMessage);
    }, 200);

    return () => {
      document.removeEventListener("message", receiveMessage);
    };
  }, [user]);

  const receiveMessage = (e) => {
    if (e.data) {
      const message = JSON.parse(e.data);

      if (!!message.contractType && !!message.fileUrl) {
        updateContractSignature(message);
      }
    }
  };

  const fetchMe = async () => {
    try {
      loadingActions.startLoading();

      await actions.fetchMe();
    } catch (e) {
      window.alert(`라이더 정보 조회에 실패했습니다. ${e?.message}`);
    }

    loadingActions.finishLoading();
  };

  const handleClickContract = (type) => {
    setContractType(type);
  };

  const handleClickEnrollPrivacyCourse = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          enrollPrivacyCourse:
            "https://sll.seoul.go.kr/lms/requestCourse/doDetailInfo.do?course_id=ASP00001S100120210103667&class_no=01&course_gubun=1&ocw_yn=N",
        }),
      );

      updateEnrollPrivacyCours();
    }
  };

  const handleClose = () => {
    setContractType(null);
  };

  const updateContractSignature = async (data) => {
    try {
      const _data = {
        [data.contractType]: data.fileUrl,
      };

      await actions.updateContractSignature(_data);

      window.alert(`서명을 저장했습니다.`);

      setContractType(null);

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            contractResult: {
              consignmentAgreement: user?.consignmentAgreement,
              personalInformationSecurityPledge:
                user?.personalInformationSecurityPledge,
              personalInformationConsentForm:
                user?.personalInformationConsentForm,
              enrolledPrivacyCourseAt: user?.enrolledPrivacyCourseAt,
              [data.contractType]: data.fileUrl,
            },
          }),
        );
      }

      fetchMe();
    } catch (e) {
      window.alert(`서명 저장에 실패했습니다. ${e?.message}`);
    }
  };

  const updateEnrollPrivacyCours = async () => {
    try {
      await actions.updateEnrollPrivacyCours();

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            contractResult: {
              consignmentAgreement: user?.consignmentAgreement,
              personalInformationSecurityPledge:
                user?.personalInformationSecurityPledge,
              personalInformationConsentForm:
                user?.personalInformationConsentForm,
              enrolledPrivacyCourseAt: new Date(),
            },
          }),
        );
      }

      fetchMe();
    } catch (e) {
      window.alert(`개인정보보호교육 수강 저장에 실패했습니다. ${e?.message}`);
    }
  };

  if (contractType) {
    return <ContractViewer type={contractType} onClose={handleClose} />;
  }

  return (
    <div className="flex justify-center items-center flex-col gap-10 min-h-screen p-10">
      <Button
        text="위탁계약서 서명"
        type={CONTRACTS.CONSIGNMENT_AGREEMENT}
        active={!!user.consignmentAgreement}
        onClick={handleClickContract}
      />
      <Button
        text="개인정보보안서약서 서명"
        type={CONTRACTS.PERSONAL_INFORMATION_SECURITY_PLEDGE}
        active={!!user.personalInformationSecurityPledge}
        onClick={handleClickContract}
      />
      <Button
        text="개인정보제공 동의서 서명"
        type={CONTRACTS.PERSONAL_INFOMATION_CONSENT_FORM}
        active={!!user.personalInformationConsentForm}
        onClick={handleClickContract}
      />
      <Button
        text="개인정보보호교육 수강"
        active={!!user.enrolledPrivacyCourseAt}
        force={true}
        onClick={handleClickEnrollPrivacyCourse}
      />
    </div>
  );
}

export default SettingContract;
