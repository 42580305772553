/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

const locationAtom = atom({
  key: "location",
  default: {},
});

export const useLocationStore = () => {
  const [location, setLocation] = useRecoilState(locationAtom);

  const updateLocation = useCallback(
    (_location) => {
      setLocation({
        ...location,
        ..._location,
      });
    },
    [location],
  );

  const state = {
    location,
  };

  return { state, updateLocation };
};
