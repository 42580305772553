import { atom, useRecoilState } from "recoil";

const modalsState = atom({
  key: "modals",
  default: [],
});

export const useModalStore = () => {
  const [modals, setModals] = useRecoilState(modalsState);

  const openModal = (component) => {
    setModals([...modals, component]);
  };

  const closeModal = () => {
    let _modals = [...modals.slice(0, -1)];
    setModals(_modals);
  };

  const state = {
    modals,
  };

  return { state, openModal, closeModal };
};
