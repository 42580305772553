import CryptoJS from "crypto-js";

export const decrypt = (encrypted) => {
  if (encrypted) {
    try {
      // 환경변수에서 key와 iv 가져오기
      const keyBytes = CryptoJS.enc.Base64.parse(
        process.env.REACT_APP_CRYPTO_KEY,
      );
      const ivBytes = CryptoJS.enc.Base64.parse(
        process.env.REACT_APP_CRYPTO_IV,
      );

      // 복호화 설정
      const decrypted = CryptoJS.AES.decrypt(encrypted, keyBytes, {
        iv: ivBytes,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      // 결과를 UTF-8 문자열로 변환
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
};
