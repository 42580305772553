/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

import api from "../Api";

import Delivery from "../../models/Delivery";

import { decrypt } from "../../utils/decrypt";

const deliveryAtom = atom({
  key: "delivery",
  default: null,
});

export const useDeliveriesStore = () => {
  const [delivery, setDelivery] = useRecoilState(deliveryAtom);

  /**
   * 직접등록건 | 배송불가지역 취소
   */
  const cancel = useCallback(
    async ({ bookId, data }) => {
      return await api.put(`/deliveries/${bookId}/cancel`, data);
    },
    [api.put],
  );

  /**
   * 배송 상세 조회
   */
  const fetchById = useCallback(
    async (bookId) => {
      const _delivery = await api.get(`/v2/deliveries/${bookId}`);

      let delivery = {
        ..._delivery,
        senderAddress: decrypt(_delivery?.senderAddress),
        senderAddressDetail: decrypt(_delivery?.senderAddressDetail),
        senderAddressRoad: decrypt(_delivery?.senderAddressRoad),
        receiverAddress: decrypt(_delivery?.receiverAddress),
        receiverAddressDetail: decrypt(_delivery?.receiverAddressDetail),
        receiverAddressRoad: decrypt(_delivery?.receiverAddressRoad),
        senderMobile: decrypt(_delivery?.senderMobile),
        senderMobile2: decrypt(_delivery?.senderMobile2),
        senderName: decrypt(_delivery?.senderName),
        receiverMobile: decrypt(_delivery?.receiverMobile),
        receiverMobile2: decrypt(_delivery?.receiverMobile2),
        receiverName: decrypt(_delivery?.receiverName),
        unrefinedAddress: decrypt(_delivery?.unrefinedAddress),
      };

      setDelivery(new Delivery(delivery));
    },
    [api.get],
  );

  /**
   * 수거지의 동일 수거 배송 목록 조회
   */
  const fetchSameSpotPickups = useCallback(
    async (bookId) => {
      return await api.get(`/deliveries/${bookId}/same-spot-pickups`);
    },
    [api.get],
  );

  /**
   * 재배차
   */
  const reallocate = useCallback(
    async ({ bookId, data }) => {
      return await api.put(`/deliveries/${bookId}/reallocation`, data);
    },
    [api.put],
  );

  /**
   * 배송 지연
   */
  const setDelay = useCallback(
    async ({ bookId, reason, reasonType }) => {
      return await api.put(`/deliveries/${bookId}/delay`, {
        reason,
        reasonType,
      });
    },
    [api.put],
  );

  /**
   * 배송 완료 위치 저장
   */
  const setDeliveryLocation = useCallback(
    async ({ bookId, data }) => {
      return await api.put(
        `/deliveries/${bookId}/delivery-completed-location`,
        data,
      );
    },
    [api.put],
  );

  /**
   * 분실 완료
   */
  const setLost = useCallback(
    async ({ bookId, reason, reasonType }) => {
      return await api.put(`/deliveries/${bookId}/lost`, {
        reason,
        reasonType,
      });
    },
    [api.put],
  );

  /**
   * 수거 완료
   */
  const setPickup = useCallback(
    async ({ bookId, data }) => {
      return await api.put(`/deliveries/${bookId}/pickup-completed`, data);
    },
    [api.put],
  );

  /**
   * 배송 연기
   */
  const setPostponed = useCallback(
    async ({ bookId }) => {
      return await api.put(`/deliveries/${bookId}/postponed`);
    },
    [api.put],
  );

  /**
   * 이전 상태로
   */
  const setPrevStatusBulk = useCallback(
    async ({ bookIds, status }) => {
      return await api.put(`/deliveries/prev`, { bookIds, status });
    },
    [api.put],
  );

  /**
   * 반송 완료
   */
  const setSentBack = useCallback(
    async ({ bookId, reason, reasonType }) => {
      return await api.put(`/deliveries/${bookId}/sent-back`, {
        reason,
        reasonType,
      });
    },
    [api.put],
  );

  /**
   * 배송 출발
   */
  const setStart = useCallback(
    async ({ bookId }) => {
      return await api.put(`/deliveries/${bookId}/delivery-start`);
    },
    [api.put],
  );

  /**
   * 주소 수정
   */
  const updateAddress = useCallback(
    async ({ bookId, data }) => {
      return await api.put(`/deliveries/${bookId}/address`, data);
    },
    [api.put],
  );

  const state = {
    delivery,
  };

  return {
    state,
    cancel,
    fetchById,
    fetchSameSpotPickups,
    reallocate,
    setDelay,
    setDeliveryLocation,
    setLost,
    setPickup,
    setPostponed,
    setPrevStatusBulk,
    setSentBack,
    setStart,
    updateAddress,
  };
};
