export const NEW_DELIVERY_TYPE_DOCUMENT = "DOCUMENT";
export const NEW_DELIVERY_TYPE_POSTBOX = "POSTBOX";

export const NEW_DELIERY_TYPES = [
  {
    text: "서류",
    code: NEW_DELIVERY_TYPE_DOCUMENT,
  },
  {
    text: "포스트박스",
    code: NEW_DELIVERY_TYPE_POSTBOX,
  },
];

export const COMPLETED_LOCATION_DOOR = "COMPLETED_LOCATION_DOOR";
export const COMPLETED_LOCATION_POSTBOX = "COMPLETED_LOCATION_POSTBOX";
export const COMPLETED_LOCATION_SECURITY = "COMPLETED_LOCATION_SECURITY";
export const COMPLETED_LOCATION_RECEIVER = "COMPLETED_LOCATION_RECEIVER";
export const COMPLETED_LOCATION_FAMILY = "COMPLETED_LOCATION_FAMILY";
export const COMPLETED_LOCATION_COWORKER = "COMPLETED_LOCATION_COWORKER";
export const COMPLETED_LOCATION_NEIGHBOR = "COMPLETED_LOCATION_NEIGHBOR";
export const COMPLETED_LOCATION_DEFAULT = "COMPLETED_LOCATION_DEFAULT";

export const COMPLETED_LOCATIONS = [
  {
    text: "문앞",
    code: COMPLETED_LOCATION_DOOR,
  },
  {
    text: "택배함(우편함)",
    code: COMPLETED_LOCATION_POSTBOX,
  },
  {
    text: "경비실",
    code: COMPLETED_LOCATION_SECURITY,
  },
  {
    text: "본인 수령",
    code: COMPLETED_LOCATION_RECEIVER,
  },
  {
    text: "가족 수령",
    code: COMPLETED_LOCATION_FAMILY,
  },
  {
    text: "직장동료 수령",
    code: COMPLETED_LOCATION_COWORKER,
  },
  {
    text: "이웃 수령",
    code: COMPLETED_LOCATION_NEIGHBOR,
    withText: false,
  },
  {
    text: "기본 보관장소",
    code: COMPLETED_LOCATION_DEFAULT,
    withText: false,
  },
];
