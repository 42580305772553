/**
 * 전화번호 대시(-) 추가
 */
export const mobileDash = (_mobile) => {
  let mobile = "";
  if (_mobile && _mobile.length > 10) {
    mobile =
      _mobile.substring(0, 3) +
      "-" +
      _mobile.substring(3, 7) +
      "-" +
      _mobile.substring(7);
  } else {
    mobile = _mobile;
  }

  return mobile;
};
