/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import Modal from "../components/Modal";

import { useModalStore } from "../stores/hooks";

const ButtonOutline = ({ onClick, children }) => {
  return (
    <button
      type="button"
      className="border-1 border-gray-8 text-gray-8 py-6 px-4 flex-1"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

function BaseAddressSettingModal({
  addressRoad,
  baseAddressMemo,
  buildingDongNumber,
  onClickDelete,
  onClickOrderUp,
  onClickOrderDown,
  onClickUpdate,
  onClickReset,
}) {
  const { closeModal } = useModalStore();

  const handleCancel = () => {
    closeModal();
  };

  const handleClickDelete = () => {
    onClickDelete();
    closeModal();
  };

  const handleClickOrderUp = () => {
    onClickOrderUp();
    closeModal();
  };

  const handleClickOrderDown = () => {
    onClickOrderDown();
    closeModal();
  };

  const handleClickUpdate = () => {
    onClickUpdate(baseAddressMemo);
    closeModal();
  };

  const handleClickReset = () => {
    onClickReset();
    closeModal();
  };

  return (
    <Modal>
      <div className="">
        <div className="pt-20 px-15 mb-20">
          <h5 className="font-medium text-16  mb-20">
            주소정보 순서변경/내용수정/삭제
          </h5>
          <p className="mb-20">{baseAddressMemo.memo}</p>
          <div className="flex justify-start items-center gap-5 mb-10">
            <ButtonOutline onClick={handleClickOrderUp}>위로</ButtonOutline>
            <ButtonOutline onClick={handleClickOrderDown}>아래로</ButtonOutline>
            <ButtonOutline onClick={handleClickUpdate}>수정</ButtonOutline>
            <ButtonOutline onClick={handleClickDelete}>삭제</ButtonOutline>
          </div>

          <button
            type="button"
            className="bg-orange text-white py-8 px-4 block w-full"
            onClick={handleClickReset}
          >
            {addressRoad}
            {buildingDongNumber ? ` ${buildingDongNumber}` : ""} 재등록
          </button>
        </div>
        <div className="flex justify-center items-center border-t-1 border-gray-8">
          <button type="button" className="flex-1 p-10" onClick={handleCancel}>
            닫기
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default BaseAddressSettingModal;
