/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import Modal from "../components/Modal";

import { useModalStore } from "../stores/hooks";

import { POST_MESSAGES_TYPES } from "../utils/postMessages";

const ROUTE_TYPES = {
  TMAP: "TMAP",
  KAKAO: "KAKAO",
  NAVER: "NAVER",
};

const RouteButton = ({ type, bgColor, onSelect, children }) => {
  const handleSelect = () => {
    onSelect(type);
  };

  return (
    <button
      type="button"
      className={`${bgColor || "bg-orange"} text-white py-4 px-12 flex-1`}
      onClick={handleSelect}
    >
      {children}
    </button>
  );
};

function DeliveryRouteModal({ delivery }) {
  const { closeModal } = useModalStore();

  const handleCancel = () => {
    closeModal();
  };

  const handleSelect = (type) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: POST_MESSAGES_TYPES.ROUTE,
          routeType: type,
          latitude: delivery.compareReceiverLocation?.latitude,
          longitude: delivery.compareReceiverLocation?.longitude,
          address:
            delivery.receiverAddressRoadWithoutSigungu ||
            delivery.receiverAddressWithoutSigungu,
        }),
      );

      closeModal();
    } else {
      window.alert("경로 찾기에 실패했습니다. 다시 시도해주세요");
    }
  };

  return (
    <Modal>
      <div className="p-15">
        <div className="mb-10">
          <h5 className="font-medium text-18 mb-20">경로 찾기</h5>
          <div className="grid gap-5">
            <RouteButton
              bgColor="bg-mint"
              type={ROUTE_TYPES.TMAP}
              onSelect={handleSelect}
            >
              티맵
            </RouteButton>
            <RouteButton
              bgColor="bg-yellow"
              type={ROUTE_TYPES.KAKAO}
              onSelect={handleSelect}
            >
              카카오맵
            </RouteButton>
            <RouteButton
              bgColor="bg-orange"
              type={ROUTE_TYPES.NAVER}
              onSelect={handleSelect}
            >
              네이버맵
            </RouteButton>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <button type="button" className="flex-1 p-10" onClick={handleCancel}>
            닫기
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeliveryRouteModal;
