export const REASON_REQUEST = "REASON_REQUEST";
export const REASON_NO_RESPONSE = "REASON_NO_RESPONSE";
export const REASON_NO_STUFF = "REASON_NO_STUFF";
export const REASON_ADDRESS_ERROR = "REASON_ADDRESS_ERROR";
export const REASON_DIRECT_RETURN = "REASON_DIRECT_RETURN";
export const REASON_ETC = "REASON_ETC";

export const CANCEL_REASONS = [
  {
    text: "고객 요청 취소",
    code: REASON_REQUEST,
  },
  {
    text: "2일 3회 전화 실패",
    code: REASON_NO_RESPONSE,
  },
  {
    text: "2회 방문 물품 없음",
    code: REASON_NO_STUFF,
  },
  {
    text: "수거주소 오류",
    code: REASON_ADDRESS_ERROR,
  },
  {
    text: "고객 직접 반품",
    code: REASON_DIRECT_RETURN,
  },
  {
    text: "기타",
    code: REASON_ETC,
  },
];
