/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

import api, { riderId } from "../Api";

const ridersAtom = atom({
  key: "riders",
  default: [],
});

const favorateRidersAtom = atom({
  key: "favorateRiders",
  default: [],
});

const pageCountAtom = atom({
  key: "ridersPageCount",
  default: 0,
});

const queryAtom = atom({
  key: "ridersQuery",
  default: {
    page: 1,
    pageSize: 10,
    sort: "name",
    text: "",
  },
});

export const useRidersStore = () => {
  const [riders, setRiders] = useRecoilState(ridersAtom);
  const [favorateRiders, setFavorateRiders] =
    useRecoilState(favorateRidersAtom);
  const [pageCount, setPageCountState] = useRecoilState(pageCountAtom);
  const [query, setQueryState] = useRecoilState(queryAtom);

  const fetchAll = useCallback(async () => {
    const response = await api.get(`/riders`, query);

    setRiders(
      query.page === 1 ? response?.rows : state.riders.concat(response?.rows),
    );
    setPageCountState(response?.pageCount || 0);
  }, [api.get, riderId, query, riders]);

  const fetchFavorateRiders = useCallback(async () => {
    const _favorateRiders = localStorage.getItem("favorateRiders");

    if (_favorateRiders) {
      const _favorateRidersArr = JSON.parse(_favorateRiders);

      try {
        const aliveRiders = await api.post(`/riders/alive`, {
          riderIds: _favorateRidersArr.map((a) => a.id),
        });

        let filteredFavorateRiders = _favorateRidersArr.filter((fr) =>
          aliveRiders.some((ar) => ar.id === fr.id && !ar.isDeleted),
        );

        setFavorateRiders(filteredFavorateRiders);

        return filteredFavorateRiders;
      } catch (e) {
        return null;
      }
    }
  }, [api.post]);

  const setFavorateRider = useCallback(
    ({ type = "SET", favorateRider, favoratedIndex }) => {
      let _favorateRiders = [...favorateRiders];

      if (type === "SET") {
        _favorateRiders.unshift(favorateRider);
      } else {
        _favorateRiders.splice(favoratedIndex, 1);
      }

      localStorage.setItem("favorateRiders", JSON.stringify(_favorateRiders));
      setFavorateRiders(_favorateRiders);
      return _favorateRiders;
    },
    [favorateRiders],
  );

  const setQuery = useCallback(
    (_query) => {
      setQueryState({
        ...query,
        ..._query,
      });
    },
    [query, setQueryState],
  );

  const state = {
    riders,
    favorateRiders,
    pageCount,
    query,
  };

  return { state, fetchAll, fetchFavorateRiders, setFavorateRider, setQuery };
};
