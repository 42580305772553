/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import Modal from "../components/Modal";

import { useModalStore } from "../stores/hooks";

function DeliveryCompletedImageModal({ image }) {
  const { closeModal } = useModalStore();

  const handleCancel = () => {
    closeModal();
  };

  return (
    <Modal>
      <div className="p-15">
        <div className="mb-20">
          <img src={image} alt="" />
        </div>
        <div className="flex justify-center items-center">
          <button type="button" className="flex-1 p-10" onClick={handleCancel}>
            닫기
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeliveryCompletedImageModal;
