/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

import History from "../../models/History";
import api, { riderId } from "../Api";

export const TERM_1DAY = "1D";
export const TERM_7DAY = "7D";
export const TERM_30DAY = "30D";

export const TYPE_DELIVER_COMPLETED = "delivery-completed";
export const TYPE_PICKUP_COMPLETED = "pickup-completed";

const historiesAtom = atom({
  key: "histories",
  default: [],
});

const pageCountAtom = atom({
  key: "historiesPageCount",
  default: 0,
});

const queryAtom = atom({
  key: "historiesQuery",
  default: {
    page: 1,
    pageSize: 20,
    type: TYPE_DELIVER_COMPLETED,
    term: TERM_1DAY,
  },
});

export const useHistoryStore = () => {
  const [histories, setHistoriesState] = useRecoilState(historiesAtom);
  const [pageCount, setPageCountState] = useRecoilState(pageCountAtom);
  const [query, setQueryState] = useRecoilState(queryAtom);

  const fetchAll = useCallback(async () => {
    if (riderId) {
      const response = await api.get(
        `/riders/${riderId}/allocated-deliveries`,
        query,
      );

      setHistoriesState(
        query.page === 1
          ? response?.rows?.map((h) => new History(h))
          : state.histories.concat(response?.rows?.map((h) => new History(h))),
      );
      setPageCountState(response?.pageCount || 0);
    }
  }, [api.get, query, riderId]);

  const setQuery = useCallback(
    (_query) => {
      setQueryState({
        ...query,
        ..._query,
      });
    },
    [query, setQueryState],
  );

  const state = {
    histories,
    pageCount,
    query,
  };

  return { state, fetchAll, setQuery };
};
