/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import Modal from "../components/Modal";

import { useModalStore } from "../stores/hooks";

function UpdateAddressDetailModal({ address, displayAddress, onFinalSubmit }) {
  const { closeModal } = useModalStore();

  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    reset({ address });
  }, []);

  const handleClickClose = () => {
    closeModal();
  };

  const onSubmit = (data) => {
    onFinalSubmit(data);
    closeModal();
  };

  return (
    <Modal>
      <div className="py-15">
        <h2 className="font-medium text-18 mb-10 px-15">상세주소입력</h2>
        <div className="px-15 py-5 bg-gray-d mb-10">{displayAddress}</div>
        <div className="mb-10 text-16 px-15">{address}</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-10 px-15">
            <div>
              <div className="bg-[#f6f6f6] p-5 flex justify-between items-center">
                <input {...register("address")} className="hidden" />
                <input
                  type="text"
                  {...register("addressDetail")}
                  className="bg-transparent flex-1"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center">
            <button
              type="button"
              className="flex-1 p-10"
              onClick={handleClickClose}
            >
              취소
            </button>
            <button type="submit" className="text-orange flex-1 p-10">
              주소확인
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default UpdateAddressDetailModal;
