/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import BaseAddressSettingModal from "./BaseAddressSettingModal";

import Modal from "../components/Modal";

import {
  useBaseAddressesStore,
  useLoadingStore,
  useModalStore,
} from "../stores/hooks";

import { sendToastMessage } from "../utils/postMessages";

const Group = ({ children }) => {
  return <div className=" mb-10">{children}</div>;
};

const GroupTitle = ({ children }) => {
  return (
    <h6 className="font-bold mb-5 pb-2 border-b-1 border-b-gray-8">
      {children}
    </h6>
  );
};

/**
 * 주소 정보 등록/수정
 */
const BaseAddressForm = ({ bookId, initBaseAddressMemo, onAfterSubmit }) => {
  const { register, handleSubmit, reset, setValue } = useForm();

  const { ...actions } = useBaseAddressesStore();

  const { ...loadingActions } = useLoadingStore();

  useEffect(() => {
    if (initBaseAddressMemo) {
      reset({
        memo: initBaseAddressMemo.memo,
      });
    }
  }, [initBaseAddressMemo]);

  const onSubmit = async (data) => {
    if (data.memo) {
      try {
        loadingActions.startLoading();

        if (initBaseAddressMemo) {
          await actions.update(initBaseAddressMemo.id, {
            memo: data.memo,
          });
        } else {
          await actions.create({
            bookId,
            memo: data.memo,
          });
        }

        sendToastMessage(
          `주소정보를 ${initBaseAddressMemo ? "변경" : "추가"}했습니다.`,
        );
        setValue("memo", "");

        onAfterSubmit();
      } catch (e) {
        window.alert(
          `주소지 정보 ${
            initBaseAddressMemo ? "수정" : "삭제"
          }에 실패했습니다. ${e.message}`,
        );
      }

      loadingActions.finishLoading();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-10">
      <div className="flex justify-between">
        <textarea
          {...register("memo")}
          placeholder="추가 정보가 있으면 입력해주세요."
          className="flex-1 border-1 border-gray-8 p-5"
        ></textarea>
        <button
          type="submit"
          className="w-70 bg-orange text-white py-8 px-4 ml-5"
        >
          {initBaseAddressMemo ? "수정" : "추가"}
        </button>
      </div>
    </form>
  );
};

/**
 * 주소 정보
 */
const BaseAddressComponent = ({
  bookId,
  addressRoad,
  baseAddressId,
  baseAddressMemo,
  buildingDongNumber,
  onUpdate,
  onAfterSubmit,
}) => {
  const { ...actions } = useBaseAddressesStore();
  const { ...loadingActions } = useLoadingStore();
  const { openModal } = useModalStore();

  const handleClickDelete = async () => {
    if (window.confirm(`${baseAddressMemo.memo}를 삭제하시겠습니까?`)) {
      try {
        await actions.deleteBaseAddress(baseAddressMemo.id);

        onAfterSubmit();
      } catch (e) {
        window.alert(`주소지 정보 삭제에 실패했습니다. ${e.message}`);
      }
    }
  };

  const handleClickOrderUp = async () => {
    try {
      await actions.updateOrder(baseAddressMemo.id, {
        order: "up",
      });

      onAfterSubmit();
    } catch (e) {
      window.alert(`주소지 정보 순서 변경에 실패했습니다. ${e.message}`);
    }
  };

  const handleClickOrderDown = async () => {
    try {
      await actions.updateOrder(baseAddressMemo.id, {
        order: "down",
      });

      onAfterSubmit();
    } catch (e) {
      window.alert(`주소지 정보 순서 변경에 실패했습니다. ${e.message}`);
    }
  };

  const handleClickReset = async () => {
    try {
      loadingActions.startLoading();
      if (baseAddressId) {
        await actions.update(baseAddressMemo.id, {
          memo: baseAddressMemo.memo,
          baseAddressId: baseAddressId,
          buildingDongNumber: buildingDongNumber,
        });
      } else {
        await actions.create({
          memo: baseAddressMemo.memo,
          bookId,
        });
        await actions.deleteBaseAddress(baseAddressMemo.id);
      }

      onAfterSubmit();
      window.alert("재등록되었습니다.");
    } catch (e) {
      window.alert(`주소지 정보 재등록에 실패했습니다. ${e.message}`);
    }

    loadingActions.finishLoading();
  };

  const handleClickBaseAddressMemo = () => {
    openModal(
      <BaseAddressSettingModal
        key="base-address-setting-modal"
        addressRoad={addressRoad}
        baseAddressMemo={baseAddressMemo}
        buildingDongNumber={buildingDongNumber}
        onClickDelete={handleClickDelete}
        onClickOrderUp={handleClickOrderUp}
        onClickOrderDown={handleClickOrderDown}
        onClickUpdate={onUpdate}
        onClickReset={handleClickReset}
      />,
    );
  };

  return (
    <button
      type="button"
      className="py-3 mb-2 block"
      onClick={handleClickBaseAddressMemo}
    >
      {baseAddressMemo.memo}
    </button>
  );
};

/**
 * 주소 정보 목록
 */
const BaseAddressList = ({
  addressRoad,
  buildingDongNumber,
  delivery,
  type = "reciever",
  onUpdate,
  onAfterSubmit,
}) => {
  const { baseAddresses, baseAddressesObj, baseAddressId } =
    useBaseAddressesStore().state;

  const baseAddressesCurrentBuildingDongGroupBy =
    baseAddressesObj.currentBuildingDong?.reduce((acc, curr) => {
      const { buildingDongNumber: bdn } = curr;
      if (acc[bdn]) acc[bdn].push(curr);
      else acc[bdn] = [curr];
      return acc;
    }, {});

  const baseAddressesWithBuildingDongWithAddressRoadGroupBy =
    baseAddressesObj.buildingDongWithAddressRoad?.reduce((acc, curr) => {
      const { buildingDongNumber: bdn } = curr;
      if (acc[bdn]) acc[bdn].push(curr);
      else acc[bdn] = [curr];
      return acc;
    }, {});

  const baseAddressesWithBuildingDongWithAddressGroupBy =
    baseAddressesObj.buildingDongWithAddress?.reduce((acc, curr) => {
      const { buildingDongNumber: bdn } = curr;
      if (acc[bdn]) acc[bdn].push(curr);
      else acc[bdn] = [curr];
      return acc;
    }, {});

  const baseAddressesNoBuildingDongWithAddressGroupBy =
    baseAddressesObj.noBuildingDongWithAddress?.reduce((acc, curr) => {
      const { baseAddress } = curr;
      if (acc[baseAddress.address]) acc[baseAddress.address].push(curr);
      else acc[baseAddress.address] = [curr];
      return acc;
    }, {});

  if (!baseAddresses.length) {
    return (
      <div className="text-center py-20">등록된 주소 정보가 없습니다.</div>
    );
  }

  return (
    <div className="max-h-[calc(100vh-300px)] overflow-auto">
      {buildingDongNumber ? (
        <>
          {baseAddressesObj.currentBuildingDong.length > 0 ||
          baseAddressesObj.buildingDongWithAddressRoad.length > 0 ? (
            <GroupTitle>{addressRoad}</GroupTitle>
          ) : (
            <></>
          )}
          {baseAddressesObj.currentBuildingDong.length > 0 && (
            <>
              {Object.entries(baseAddressesCurrentBuildingDongGroupBy).map(
                ([key, value], index) => (
                  <Group
                    key={"baseAddressesCurrentBuildingDongGroupBy" + index}
                  >
                    <GroupTitle>{key}동 정보</GroupTitle>
                    {value.map((baseAddressMemo) => {
                      return (
                        <BaseAddressComponent
                          key={baseAddressMemo.id}
                          bookId={delivery.bookId}
                          baseAddressMemo={baseAddressMemo}
                          baseAddressId={baseAddressId}
                          addressRoad={addressRoad}
                          buildingDongNumber={buildingDongNumber}
                          onUpdate={onUpdate}
                          onAfterSubmit={onAfterSubmit}
                        />
                      );
                    })}
                  </Group>
                ),
              )}
            </>
          )}
          {baseAddressesObj.buildingDongWithAddressRoad.length > 0 && (
            <>
              {Object.entries(
                baseAddressesWithBuildingDongWithAddressRoadGroupBy,
              ).map(([key, value], index) => (
                <Group
                  key={
                    "baseAddressesWithBuildingDongWithAddressRoadGroupBy" +
                    index
                  }
                >
                  <GroupTitle>{key}동 정보</GroupTitle>
                  {value.map((baseAddressMemo) => {
                    return (
                      <BaseAddressComponent
                        key={baseAddressMemo.id}
                        bookId={delivery.bookId}
                        baseAddressMemo={baseAddressMemo}
                        baseAddressId={baseAddressId}
                        addressRoad={addressRoad}
                        buildingDongNumber={buildingDongNumber}
                        onUpdate={onUpdate}
                        onAfterSubmit={onAfterSubmit}
                      />
                    );
                  })}
                </Group>
              ))}
            </>
          )}

          {baseAddressesObj.noBuildingDongWithAddressRoad.length > 0 ? (
            <>
              <GroupTitle>{addressRoad}</GroupTitle>
              {baseAddressesObj.noBuildingDongWithAddressRoad.map(
                (baseAddressMemo) => {
                  return (
                    <BaseAddressComponent
                      key={baseAddressMemo.id}
                      bookId={delivery.bookId}
                      baseAddressMemo={baseAddressMemo}
                      baseAddressId={baseAddressId}
                      addressRoad={addressRoad}
                      buildingDongNumber={buildingDongNumber}
                      onUpdate={onUpdate}
                      onAfterSubmit={onAfterSubmit}
                    />
                  );
                },
              )}
            </>
          ) : (
            <></>
          )}

          {baseAddressesObj.buildingDongWithAddress.length > 0 && (
            <>
              {Object.entries(
                baseAddressesWithBuildingDongWithAddressGroupBy,
              ).map(([key, value], index) => (
                <Group
                  key={
                    "baseAddressesWithBuildingDongWithAddressGroupBy" + index
                  }
                >
                  <GroupTitle>
                    {value[0]?.baseAddress.address}
                    <br />
                    {key}동 정보
                  </GroupTitle>
                  {value.map((baseAddressMemo) => {
                    return (
                      <BaseAddressComponent
                        key={baseAddressMemo.id}
                        bookId={delivery.bookId}
                        baseAddressMemo={baseAddressMemo}
                        baseAddressId={baseAddressId}
                        addressRoad={addressRoad}
                        buildingDongNumber={buildingDongNumber}
                        onUpdate={onUpdate}
                        onAfterSubmit={onAfterSubmit}
                      />
                    );
                  })}
                </Group>
              ))}
            </>
          )}

          {baseAddressesObj.noBuildingDongWithAddress.length > 0 ? (
            <>
              {Object.entries(
                baseAddressesNoBuildingDongWithAddressGroupBy,
              ).map(([key, value], index) => (
                <Group
                  key={"baseAddressesNoBuildingDongWithAddressGroupBy" + index}
                >
                  <GroupTitle>{key}</GroupTitle>
                  {value.map((baseAddressMemo) => {
                    return (
                      <BaseAddressComponent
                        key={baseAddressMemo.id}
                        bookId={delivery.bookId}
                        baseAddressMemo={baseAddressMemo}
                        baseAddressId={baseAddressId}
                        addressRoad={addressRoad}
                        buildingDongNumber={buildingDongNumber}
                        onUpdate={onUpdate}
                        onAfterSubmit={onAfterSubmit}
                      />
                    );
                  })}
                </Group>
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {baseAddressesObj.noBuildingDongWithAddressRoad.length > 0 ? (
            <>
              <GroupTitle>{addressRoad}</GroupTitle>
              {baseAddressesObj.noBuildingDongWithAddressRoad.map(
                (baseAddressMemo) => {
                  return (
                    <BaseAddressComponent
                      key={baseAddressMemo.id}
                      bookId={delivery.bookId}
                      baseAddressMemo={baseAddressMemo}
                      baseAddressId={baseAddressId}
                      addressRoad={addressRoad}
                      buildingDongNumber={buildingDongNumber}
                      onUpdate={onUpdate}
                      onAfterSubmit={onAfterSubmit}
                    />
                  );
                },
              )}
            </>
          ) : (
            <></>
          )}
          {baseAddressesObj.buildingDongWithAddressRoad.length > 0 && (
            <>
              {Object.entries(
                baseAddressesWithBuildingDongWithAddressRoadGroupBy,
              ).map(([key, value], index) => (
                <Group
                  key={
                    "baseAddressesWithBuildingDongWithAddressRoadGroupBy" +
                    index
                  }
                >
                  <GroupTitle>{key}동 정보</GroupTitle>
                  {value.map((baseAddressMemo) => {
                    return (
                      <BaseAddressComponent
                        key={baseAddressMemo.id}
                        bookId={delivery.bookId}
                        baseAddressMemo={baseAddressMemo}
                        baseAddressId={baseAddressId}
                        addressRoad={addressRoad}
                        buildingDongNumber={buildingDongNumber}
                        onUpdate={onUpdate}
                        onAfterSubmit={onAfterSubmit}
                      />
                    );
                  })}
                </Group>
              ))}
            </>
          )}
          {baseAddressesObj.noBuildingDongWithAddress.length > 0 ? (
            <>
              {Object.entries(
                baseAddressesNoBuildingDongWithAddressGroupBy,
              ).map(([key, value], index) => (
                <Group
                  key={"baseAddressesNoBuildingDongWithAddressGroupBy" + index}
                >
                  <GroupTitle>{key}</GroupTitle>
                  {value.map((baseAddressMemo) => {
                    return (
                      <BaseAddressComponent
                        key={baseAddressMemo.id}
                        bookId={delivery.bookId}
                        baseAddressMemo={baseAddressMemo}
                        baseAddressId={baseAddressId}
                        addressRoad={addressRoad}
                        buildingDongNumber={buildingDongNumber}
                        onUpdate={onUpdate}
                        onAfterSubmit={onAfterSubmit}
                      />
                    );
                  })}
                </Group>
              ))}
            </>
          ) : (
            <></>
          )}
          {baseAddressesObj.buildingDongWithAddress.length > 0 && (
            <>
              {Object.entries(
                baseAddressesWithBuildingDongWithAddressGroupBy,
              ).map(([key, value], index) => (
                <Group
                  key={
                    "baseAddressesWithBuildingDongWithAddressGroupBy" + index
                  }
                >
                  <GroupTitle>
                    {value[0]?.baseAddress.address}
                    <br />
                    {key}동 정보
                  </GroupTitle>
                  {value.map((baseAddressMemo) => {
                    return (
                      <BaseAddressComponent
                        key={baseAddressMemo.id}
                        bookId={delivery.bookId}
                        baseAddressMemo={baseAddressMemo}
                        baseAddressId={baseAddressId}
                        addressRoad={addressRoad}
                        buildingDongNumber={buildingDongNumber}
                        onUpdate={onUpdate}
                        onAfterSubmit={onAfterSubmit}
                      />
                    );
                  })}
                </Group>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

function BaseAddressModal({ delivery, type = "receiver", onAfterSubmit }) {
  const { ...actions } = useBaseAddressesStore();

  const { ...loadingActions } = useLoadingStore();

  const { closeModal } = useModalStore();

  const [address, setAddress] = useState("");
  const [addressRoad, setAddressRoad] = useState("");
  const [buildingDongNumber, setBuildingDongNumber] = useState("");

  const [initBaseAddressMemo, setInitBaseAddressMemo] = useState(null);

  const handleCancel = () => {
    closeModal();
  };

  const handleClickUpdate = (_baseAddressMemo) => {
    setInitBaseAddressMemo(_baseAddressMemo);
  };

  useEffect(() => {
    const _address =
      type === "sender"
        ? delivery.senderAddressRoadWithoutSigungu ||
          delivery.senderAddressWithoutSigungu
        : delivery.receiverAddressRoadWithoutSigungu ||
          delivery.receiverAddressWithoutSigungu;

    const _addressRoad =
      type === "sender"
        ? delivery?.senderAddressRoad
        : delivery?.receiverAddressRoad;

    const _buildingDongNumber =
      type === "sender"
        ? delivery?.senderBuildingDongNumber
        : delivery?.receiverBuildingDongNumber;

    setAddress(_address);
    setAddressRoad(_addressRoad);
    setBuildingDongNumber(_buildingDongNumber);

    fetchBaseAddressId();
  }, [delivery]);

  /**
   * baseAddressId 조회
   */
  const fetchBaseAddressId = async () => {
    try {
      loadingActions.startLoading();

      let _addressRoad =
        type === "sender"
          ? delivery.senderAddressRoad
          : delivery.receiverAddressRoad;

      if (_addressRoad) {
        await actions.fetchBaseAddressId({
          addressRoad: _addressRoad,
        });
      }
    } catch (e) {
      window.alert(`주소 정보 id 조회에 실패했습니다. ${e.message}`);
    }

    loadingActions.finishLoading();
  };

  return (
    <Modal>
      <div className="p-15">
        <div className="mb-10">
          <h5 className="font-medium text-18 mb-10">
            {address}
            {buildingDongNumber ? `(${buildingDongNumber})` : ""}
          </h5>

          {/** 주소 정보 등록/수정 폼 */}
          <BaseAddressForm
            bookId={delivery.bookId}
            initBaseAddressMemo={initBaseAddressMemo}
            onAfterSubmit={onAfterSubmit}
          />

          {/** 주소 정보 목록 */}
          <BaseAddressList
            addressRoad={addressRoad}
            buildingDongNumber={buildingDongNumber}
            delivery={delivery}
            type={type}
            onUpdate={handleClickUpdate}
            onAfterSubmit={onAfterSubmit}
          />
        </div>
        <div className="flex justify-center items-center">
          <button type="button" className="flex-1 p-10" onClick={handleCancel}>
            닫기
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default BaseAddressModal;
