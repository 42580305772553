/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import Modal from "../components/Modal";

import { useModalStore } from "../stores/hooks";

function AlertModal({ message, submitText = "닫기", onSubmit = () => {} }) {
  const { closeModal } = useModalStore();

  const handleSubmit = () => {
    onSubmit();
    closeModal();
  };

  return (
    <Modal>
      <div className="">
        <div className="pt-20 px-15 mb-20">
          <h5 className="text-16 text-center">{message}</h5>
        </div>
        <div className="flex justify-end items-center border-t-1 border-gray-8">
          <button type="button" className="flex-1 p-10" onClick={handleSubmit}>
            {submitText}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AlertModal;
