/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import AlertModal from "../modals/AlertModal";
import BaseAddressModal from "../modals/BaseAddressModal";
import CancelDeliveryPickupReturnModal from "../modals/CancelDeliveryPickupReturnModal";
import ConfirmModal from "../modals/ConfirmModal";
import ConvenienceBarcodeModal from "../modals/ConvenienceBarcodeModal";
import CreateBaseAddressByFrontdoorModal from "../modals/CreateBaseAddressByFrontdoorModal";
import DeliveryCompletedImageModal from "../modals/DeliveryCompletedImageModal";
import DeliveryDelayModal from "../modals/DeliveryDelayModal";
import DeliveryLostModal from "../modals/DeliveryLostModal";
import DeliveryRouteModal from "../modals/DeliveryRouteModal";
import DeliverySentBackModal from "../modals/DeliverySentBackModal";
import ReallocationRidersModal from "../modals/ReallocationRidersModal";
import SendMessageModal from "../modals/SendMessageModal";
import UpdateAddressModal from "../modals/UpdateAddressModal";
import UpdateCompletedLocationModal from "../modals/UpdateCompletedLocationModal";

import {
  useBaseAddressesStore,
  useDeliveriesStore,
  useLoadingStore,
  useLocationStore,
  useModalStore,
} from "../stores/hooks";

import { formatDate } from "../lib/date";

import {
  POST_MESSAGES_TYPES,
  goToPickups,
  sendToastMessage,
} from "../utils/postMessages";

const InfoRow = ({ children }) => {
  return <dl className="flex justify-start items-start">{children}</dl>;
};

const InfoLabel = ({ children }) => {
  return <dt className="w-80 text-[#828182] text-16">{children}</dt>;
};

const InfoValue = ({ children }) => {
  return <dd className="flex-1 text-16">{children}</dd>;
};

const ButtonsWrap = ({ children }) => {
  return (
    <div className="flex justify-start items-center gap-5">{children}</div>
  );
};

const ButtonSquare = ({ onClick, bgColor, children }) => {
  return (
    <button
      type="button"
      className={`${bgColor || "bg-orange"} text-white py-4 px-12 flex-1`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const ButtonRound = ({ onClick, type, minWidth, children }) => {
  let classNames = "bg-orange text-white";
  if (type === "ORANGE") {
    classNames = "bg-orange text-white";
  } else if (type === "MINT") {
    classNames = "bg-mint text-white";
  }

  return (
    <button
      type="button"
      className={`py-2 px-12 rounded-full ${classNames} ${minWidth}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

/**
 * 주소 정보 버튼
 */
const BaseAddressButton = ({ delivery, type = "receiver", onAfterSubmit }) => {
  const { baseAddressesAll } = useBaseAddressesStore().state;
  const { openModal } = useModalStore();

  const handleClickBaseAddress = () => {
    if (type === "sender" || delivery.receiverPoint) {
      openModal(
        <BaseAddressModal
          key="base-address-modal"
          delivery={delivery}
          type={type}
          onAfterSubmit={onAfterSubmit}
        />,
      );
    } else {
      openModal(
        <AlertModal
          key="alert-modal"
          message="위치등록 버튼을 터치하여 주소 등록 후 이용해주십시오."
        />,
      );
    }
  };
  return (
    <ButtonSquare bgColor="bg-mint" onClick={handleClickBaseAddress}>
      주소정보{baseAddressesAll?.length ? "(!)" : ""}
    </ButtonSquare>
  );
};

/**
 * 전화 버튼
 */
const CallButton = ({ mobile, label }) => {
  const handleClickCall = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: POST_MESSAGES_TYPES.CALL,
          mobile,
        }),
      );
    } else {
      window.alert("통화에 실패했습니다. 다시 시도해주세요");
    }
  };

  return (
    <button
      type="button"
      className="bg-white text-gray-a border-1 border-gray-d py-2 px-12 rounded-10 ml-5"
      onClick={handleClickCall}
    >
      {label || "통화"}
    </button>
  );
};

/**
 * 직접등록건 취소 버튼
 */
const CancelDeliveryButton = ({ delivery }) => {
  const { ...actions } = useDeliveriesStore();
  const { ...loadingActions } = useLoadingStore();
  const { openModal } = useModalStore();

  const handleClickCancelDelivery = () => {
    openModal(
      <ConfirmModal
        key="confirm-modal"
        message={`${delivery.bookId} 건을 취소하시겠습니까?`}
        onSubmit={cancelDelivery}
      />,
    );
  };

  const cancelDelivery = async () => {
    try {
      loadingActions.startLoading();

      const data = {
        nonDoobal: true,
      };

      await actions.cancel({
        bookId: delivery.bookId,
        data,
      });

      openModal(
        <AlertModal
          key="alert-modal"
          message="취소처리 되었습니다."
          onSubmit={goToDeliveries}
        />,
      );

      loadingActions.finishLoading();
    } catch (e) {
      window.alert(`취소에 실패했습니다. ${e.message}`);
      loadingActions.finishLoading();
    }
  };

  const goToDeliveries = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: POST_MESSAGES_TYPES.NAVIGATION,
          to: "deliveries",
        }),
      );
    } else {
      window.alert("페이지 이동에 실패했습니다. 앱 종료후 다시 시도해주세요");
    }
  };

  return (
    <ButtonSquare bgColor="bg-mint" onClick={handleClickCancelDelivery}>
      직접등록건 취소
    </ButtonSquare>
  );
};

/**
 * 배송/반송/분실 완료 취소 버튼
 */
const CancelDeliveryCompletedButton = ({ delivery, label, onAfterSubmit }) => {
  const { ...actions } = useDeliveriesStore();
  const { ...loadingActions } = useLoadingStore();

  const handleClickCancelDeliveryCompleted = async () => {
    if (
      window.confirm(
        `${delivery.bookId} ${delivery.statusString}를 취소하시겠습니까?`,
      )
    ) {
      try {
        loadingActions.startLoading();

        await actions.setPrevStatusBulk({
          bookIds: [delivery.bookId],
          status: delivery.statusNubmer,
        });

        window.alert(
          `${delivery.bookId} ${delivery.statusString}를 취소했습니다. 실제 배송목록에서 배송건을 확인할 수 있습니다.`,
        );

        onAfterSubmit();
      } catch (e) {
        window.alert(
          `${delivery.statusString} 취소에 실패했습니다. ${e.message}`,
        );
        loadingActions.finishLoading();
      }
    }
  };

  return (
    <ButtonSquare
      bgColor="bg-orange"
      onClick={handleClickCancelDeliveryCompleted}
    >
      {label} 취소
    </ButtonSquare>
  );
};

/**
 * 배송 불가 지역 배차 취소 버튼
 */
const CancelDeliveryNotSupportedButton = ({ delivery }) => {
  const { ...actions } = useDeliveriesStore();
  const { ...loadingActions } = useLoadingStore();
  const { openModal } = useModalStore();

  const handleClickCancelDeliveryNotSupported = () => {
    openModal(
      <ConfirmModal
        key="confirm-modal"
        message={`${delivery.bookId} 건을 배차취소하시겠습니까?`}
        onSubmit={cancelDeliveryNotSupported}
      />,
    );
  };

  const cancelDeliveryNotSupported = async () => {
    try {
      loadingActions.startLoading();

      const data = {
        nonDoobal: false,
      };

      await actions.cancel({
        bookId: delivery.bookId,
        data,
      });

      openModal(
        <AlertModal
          key="alert-modal"
          message="취소처리 되었습니다."
          onSubmit={goToDeliveries}
        />,
      );

      loadingActions.finishLoading();
    } catch (e) {
      window.alert(`취소에 실패했습니다. ${e.message}`);
      loadingActions.finishLoading();
    }
  };

  const goToDeliveries = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: POST_MESSAGES_TYPES.NAVIGATION,
          to: "deliveries",
        }),
      );
    } else {
      window.alert("페이지 이동에 실패했습니다. 앱 종료후 다시 시도해주세요");
    }
  };

  return (
    <ButtonSquare
      bgColor="bg-mint"
      onClick={handleClickCancelDeliveryNotSupported}
    >
      배송불가지역 배차 취소
    </ButtonSquare>
  );
};

/**
 * 픽반품건 취소 버튼
 */
const CancelDeliveryPickupReturnButton = ({ delivery }) => {
  const { openModal } = useModalStore();

  const handleClickCancelDeliveryPickupReturn = () => {
    openModal(
      <CancelDeliveryPickupReturnModal
        key="cancel-delivery-pickup-return-modal"
        delivery={delivery}
      />,
    );
  };

  return (
    <ButtonRound
      type="ORANGE"
      minWidth="min-w-[80px]"
      onClick={handleClickCancelDeliveryPickupReturn}
    >
      취소
    </ButtonRound>
  );
};

/**
 * 편의점 바코드 버튼
 */
const ConvenienceBarcodeButton = ({ delivery }) => {
  const { openModal } = useModalStore();

  const handleClickConvenienceBarcode = () => {
    openModal(
      <ConvenienceBarcodeModal
        key="convenience-barcode-modal"
        delivery={delivery}
      />,
    );
  };

  return (
    <ButtonRound
      type="MINT"
      minWidth="min-w-[80px]"
      onClick={handleClickConvenienceBarcode}
    >
      GS25
    </ButtonRound>
  );
};

/**
 * 배송 완료 컴포넌트
 */
const DeliveryCompleteComponent = ({ delivery, onAfterSubmit }) => {
  if (delivery.spotKurlyDrink || delivery.mustSignature) {
    return (
      <ButtonsWrap>
        <button
          type="button"
          className="bg-orange text-white py-4 px-12 flex-1"
        >
          수취확인
        </button>
      </ButtonsWrap>
    );
  }

  return (
    <>
      <ButtonsWrap>
        <button
          type="button"
          className="bg-orange text-white py-4 px-12 flex-1"
        >
          사진촬영
        </button>
      </ButtonsWrap>
      <ButtonsWrap>
        <button
          type="button"
          className="bg-orange text-white py-4 px-12 flex-1"
        >
          사진선택
        </button>
        <button
          type="button"
          className="bg-orange text-white py-4 px-12 flex-1"
        >
          수취확인
        </button>
      </ButtonsWrap>
    </>
  );
};

/**
 * 서명/사진 보기 버튼
 */
const DeliveryCompletedImageButton = ({ completedImage }) => {
  const { openModal } = useModalStore();

  const handleClickCompletedImage = () => {
    openModal(
      <DeliveryCompletedImageModal
        key="delivery-completed-image-modal"
        image={completedImage}
      />,
    );
  };

  return (
    <ButtonSquare onClick={handleClickCompletedImage}>
      서명/사진 보기
    </ButtonSquare>
  );
};

/**
 * 배송/분실/반송 완료 위치
 */
const DeliveryCompletedLocationButton = ({
  bgColor = "bg-orange",
  flex = 1,
  completedLatitude,
  completedLongitude,
  label,
}) => {
  const handleClickCompletedLocation = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: POST_MESSAGES_TYPES.COMPLETED_LOCATION,
          latitude: completedLatitude,
          longitude: completedLongitude,
        }),
      );
    } else {
      window.alert(`${label} 보기에 실패했습니다. 다시 시도해주세요`);
    }
  };

  return (
    <button
      type="button"
      className={`${bgColor} text-white py-4 px-12 ${
        flex === 2 ? "flex-[2]" : "flex-1"
      }`}
      onClick={handleClickCompletedLocation}
    >
      {label}
    </button>
  );
};

/**
 * 배송 지연 버튼
 */
const DeliveryDelayButton = ({ delivery, onAfterSubmit }) => {
  const { openModal } = useModalStore();

  const handleClickDelay = () => {
    openModal(
      <DeliveryDelayModal
        key="delivery-delay-modal"
        delivery={delivery}
        onAfterSubmit={onAfterSubmit}
      />,
    );
  };

  return (
    <ButtonRound
      type="ORANGE"
      minWidth="min-w-[80px]"
      onClick={handleClickDelay}
    >
      {delivery.recentDelayedDelivery
        ? formatDate(delivery.recentDelayedDelivery.createdAt, "HH:mm")
        : "배송지연"}
      {delivery.isrequiredDelayMessage && "(!)"}
    </ButtonRound>
  );
};

/**
 * 위치보기 버튼
 */
const DeliveryLocationButton = ({ type }) => {
  const handleClickLocation = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: POST_MESSAGES_TYPES.MAP,
          deliveryType: type,
        }),
      );
    } else {
      window.alert("위치보기에 실패했습니다. 다시 시도해주세요");
    }
  };

  return (
    <ButtonSquare bgColor="bg-mint" onClick={handleClickLocation}>
      위치보기
    </ButtonSquare>
  );
};

/**
 * 분실 완료 버튼
 */
const DeliveryLostButton = ({ delivery, onAfterSubmit }) => {
  const { openModal } = useModalStore();

  const handleClickLost = () => {
    openModal(
      <DeliveryLostModal
        key="delivery-lost-modal"
        delivery={delivery}
        onAfterSubmit={onAfterSubmit}
      />,
    );
  };

  return (
    <button
      type="button"
      className="bg-orange text-white py-2 px-8 rounded-10 ml-5"
      onClick={handleClickLost}
    >
      분실완료
    </button>
  );
};

/**
 * 배송 연기 버튼
 */
const DeliveryPostponedButton = ({ delivery, onAfterSubmit }) => {
  const { ...actions } = useDeliveriesStore();
  const { ...loadingActions } = useLoadingStore();

  const handleClickPostponed = async () => {
    if (
      window.confirm(
        `${delivery.bookId} 건을 배송연기 상태로 변경하시겠습니까?`,
      )
    ) {
      try {
        loadingActions.startLoading();
        await actions.setPostponed({ bookId: delivery.bookId });

        onAfterSubmit();
      } catch (e) {
        window.alert(`배송연기 처리에 실패했습니다. ${e.message}`);
        loadingActions.finishLoading();
      }
    }
  };

  return (
    <ButtonSquare bgColor="bg-orange" onClick={handleClickPostponed}>
      연기등록
    </ButtonSquare>
  );
};

/**
 * 경로찾기 버튼
 */
const DeliveryRouteButton = ({ delivery }) => {
  const { openModal } = useModalStore();

  const handleClickRoute = () => {
    openModal(
      <DeliveryRouteModal key="delivery-route-modal" delivery={delivery} />,
    );
  };

  return (
    <ButtonSquare bgColor="bg-mint" onClick={handleClickRoute}>
      경로찾기
    </ButtonSquare>
  );
};

/**
 * 반송 완료 버튼
 */
const DeliverySentBackButton = ({ delivery, onAfterSubmit }) => {
  const { openModal } = useModalStore();

  const handleClickSentBack = () => {
    openModal(
      <DeliverySentBackModal
        key="delivery-sentback-modal"
        delivery={delivery}
        onAfterSubmit={onAfterSubmit}
      />,
    );
  };

  return (
    <button
      type="button"
      className="bg-orange text-white py-2 px-8 rounded-10 ml-5"
      onClick={handleClickSentBack}
    >
      반송완료
    </button>
  );
};

/**
 * 실배송 버튼
 */
const DeliveryStartButton = ({ delivery, onAfterSubmit }) => {
  const { ...actions } = useDeliveriesStore();
  const { ...loadingActions } = useLoadingStore();

  const handleClickStart = async () => {
    if (
      window.confirm(
        `${delivery.bookId} 건을 배송출발 상태로 변경하시겠습니까?`,
      )
    ) {
      try {
        loadingActions.startLoading();
        await actions.setStart({ bookId: delivery.bookId });

        onAfterSubmit();
      } catch (e) {
        window.alert(`실배송 처리에 실패했습니다. ${e.message}`);
        loadingActions.finishLoading();
      }
    }
  };

  return (
    <ButtonSquare bgColor="bg-orange" onClick={handleClickStart}>
      실배송등록
    </ButtonSquare>
  );
};

/**
 * 현관 비밀번호 버튼
 */
const FrontdoorPasswordButton = ({ delivery, onAfterSubmit }) => {
  const { openModal } = useModalStore();

  const handleClickFrontdoorePassword = () => {
    openModal(
      <CreateBaseAddressByFrontdoorModal
        key="create-base-address-by-frontdoor-modal"
        delivery={delivery}
        onAfterSubmit={onAfterSubmit}
      />,
    );
  };

  return (
    <button
      className="bg-orange text-white text-20 text-center rounded-4 w-full relative px-20 py-2"
      type="button"
      onClick={handleClickFrontdoorePassword}
    >
      <img
        src="/images/key.png"
        alt=""
        className="absolute w-32 h-32 left-5 top-[50%] mt-[-18px]"
      />
      {delivery.frontdoorPassword}
    </button>
  );
};

/**
 * 수거 완료 버튼
 */
const PickupButton = ({ delivery }) => {
  const { ...actions } = useDeliveriesStore();
  const { ...loadingActions } = useLoadingStore();
  const { openModal } = useModalStore();

  const handleClickPickup = async () => {
    if (delivery?.typeNormal && !!delivery?.spot) {
      try {
        loadingActions.startLoading();

        // 수거지의 동일 수거건 목록 조회
        const sameSpotPickups = await actions.fetchSameSpotPickups(
          delivery.bookId,
        );

        loadingActions.finishLoading();

        if (sameSpotPickups?.length > 1) {
          openModal(
            <ConfirmModal
              key="confirm-modal"
              message={`${delivery.spotName} 수거지에 총 ${sameSpotPickups.length}건이 수거지정되어 있습니다.\n모두 수거하시겠습니까?`}
              onSubmit={() => pickupBulk(sameSpotPickups)}
              onCancel={confirmPickup}
            />,
          );
        } else {
          confirmPickup();
        }
      } catch (e) {
        window.alert(`수거 건이 확인되지 않습니다. ${e.message}`);
        loadingActions.finishLoading();
      }
    } else {
    }
  };

  const confirmPickup = () => {
    console.log("confirmPickup");
  };

  const pickupBulk = async (sameSpotPickups) => {
    if (sameSpotPickups.length > 0) {
      try {
      } catch (e) {
        window.alert(`수거 처리가 되지 않았습니다. ${e.message}`);
      }
    } else {
      window.alert("수거 내역이 없습니다.");
    }
  };

  return (
    <ButtonSquare bgColor="bg-orange" onClick={handleClickPickup}>
      수거완료
    </ButtonSquare>
  );
};

/**
 * 수거 완료(픽반품) 컴포넌트
 */
const PickupCompleteComponent = () => {
  const handleClickSelectPicture = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: POST_MESSAGES_TYPES.SELECT_PICTURE,
          deliveryType: "pickup",
        }),
      );
    } else {
      window.alert("앱과의 통신에 실패했습니다. 다시 시도해주세요");
    }
  };

  return (
    <div className="flex justify-start items-center gap-5">
      <button
        type="button"
        className="bg-orange text-white py-4 px-12 flex-1"
        onClick={handleClickSelectPicture}
      >
        사진선택
      </button>
      <button type="button" className="bg-orange text-white py-4 px-12 flex-1">
        수거촬영
      </button>
    </div>
  );
};

/**
 * 재배차 버튼
 */
const ReallocationButton = ({ delivery }) => {
  const { openModal } = useModalStore();

  const handleClickReallocation = () => {
    if (delivery.reallocatedRiderId) {
      sendToastMessage("관제팀 확인 중");
    } else {
      openModal(
        <ReallocationRidersModal
          key="reallocation-riders-modal"
          delivery={delivery}
        />,
      );
    }
  };

  return (
    <button
      type="button"
      className={`${
        delivery.reallocatedRiderId ? " text-gray-a" : " text-orange"
      } bg-white py-2 px-12 rounded-full min-w-[80px] flex justify-center items-center`}
      onClick={handleClickReallocation}
    >
      <img
        src={`/images/rearrange${
          delivery.reallocatedRiderId ? "" : "-red"
        }.png`}
        alt=""
        className="w-24"
      />
      재배차
    </button>
  );
};

/**
 * 위치 등록 버튼
 */
const ResetAddressButton = ({ delivery, type = "receiver", onAfterSubmit }) => {
  const { openModal } = useModalStore();

  const handleClickResetAddress = () => {
    openModal(
      <UpdateAddressModal
        key="update-address-modal"
        delivery={delivery}
        type={type}
        updateType="reset"
        onAfterSubmit={onAfterSubmit}
      />,
    );
  };

  return (
    <ButtonSquare onClick={handleClickResetAddress}>
      위치 등록 (필수)
    </ButtonSquare>
  );
};

/**
 * 문자 발송 버튼
 */
const SendMessageButton = ({ delivery, mobile, type }) => {
  const { openModal } = useModalStore();

  const handleClickMessage = () => {
    if (type === "pickupReturn") {
      openModal(
        <ConfirmModal
          key="confirm-modal"
          message="반품수거 안내 문자를 발송하시겠습니까?"
          onSubmit={sendMessageForPickup}
        />,
      );
    } else {
      openModal(<SendMessageModal key="send-message-modal" mobile={mobile} />);
    }
  };

  const sendMessageForPickup = () => {
    const message = `[두발히어로 반품수거 안내] 고객님 오늘 ${
      delivery.receiverName
    } 물품의 반품 수거를 위해 방문 예정입니다. 수거할 물품박스에 구매처 브랜드명과, 두발히어로를 크게 기재해 주시고, 문앞이나 수거 가능한 보관 위치에 물품을 보관 후 문자로 꼭 회신 부탁드립니다.\n공동현관 출입 시 비밀번호가 필요하다면 회신 문자에 포함 부탁드리겠습니다.\n■반품/수거 접수처 : ${
      delivery.receiverName
    }\n■접수번호 : ${delivery.bookId}\n■방문주소 : ${
      delivery.senderAddressRoad || ""
    }`;

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: POST_MESSAGES_TYPES.SEND_MESSAGE_FOR_DIRECT,
          mobile,
          message,
        }),
      );
    } else {
      window.alert("문자 발송에 실패했습니다. 다시 시도해주세요");
    }
  };

  return (
    <button
      type="button"
      className="bg-white text-gray-a border-1 border-gray-d py-2 px-12 rounded-10 ml-5"
      onClick={handleClickMessage}
    >
      문자
    </button>
  );
};

/**
 * 주소 수정 버튼
 */
const UpdateAddressButton = ({
  delivery,
  type = "receiver",
  onAfterSubmit,
}) => {
  const { openModal } = useModalStore();

  const handleClickUpdateAddress = () => {
    openModal(
      <UpdateAddressModal
        key="update-address-modal"
        delivery={delivery}
        type={type}
        updateType="update"
        onAfterSubmit={onAfterSubmit}
      />,
    );
  };

  return (
    <ButtonSquare bgColor="bg-orange" onClick={handleClickUpdateAddress}>
      주소 수정
    </ButtonSquare>
  );
};

/**
 * 배송 완료 위치 수정 버튼
 */
const UpdateDeliveryCompletedLocationButton = ({ delivery, onAfterSubmit }) => {
  const { openModal } = useModalStore();

  const handleClickUpdateLocation = () => {
    openModal(
      <UpdateCompletedLocationModal
        key="update-completed-location-modal"
        delivery={delivery}
        cancelable={true}
        onAfterSubmit={onAfterSubmit}
      />,
    );
  };

  return (
    <button
      type="button"
      className={`${
        delivery.sendedCompletedSms
          ? "bg-mint border-mint"
          : "bg-orange border-orange"
      } text-white border-1  py-2 px-12 rounded-10 ml-5`}
      onClick={handleClickUpdateLocation}
    >
      위치
    </button>
  );
};

function Delivery() {
  const params = new URLSearchParams(window.location.search);
  const bookId = params.get("bookId");
  const failedFile = params.get("failedFile");

  const { baseAddresses } = useBaseAddressesStore().state;
  const { ...baseAddressesActions } = useBaseAddressesStore();

  const { delivery } = useDeliveriesStore().state;
  const { ...actions } = useDeliveriesStore();

  const { ...loadingActions } = useLoadingStore();

  // const { location } = useLocationStore().state;
  // const { ...locationActions } = useLocationStore();

  useEffect(() => {
    if (bookId) {
      fetchById();
    }
  }, [bookId]);

  useEffect(() => {
    if (delivery?.bookId === bookId) {
      fetchBaseAddressById();
    }
  }, [delivery, bookId]);

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener("message", handleWebviewMessage);
    }, 200);

    return () => {
      document.removeEventListener("message", handleWebviewMessage);
    };
  }, []);

  const handleWebviewMessage = (e) => {
    if (e.data) {
      const data = JSON.parse(e.data);

      // if (data.type === "location" && data.location) {
      //   locationActions.updateLocation(data.location);
      // } else
      if (data.type === "selectPicture" && data.deliveryType && data.path) {
        if (data.deliveryType === "pickup") {
          setPickup({ path: data.path, location: data.location });
        }
      }
    }
  };

  const fetchById = async () => {
    try {
      loadingActions.startLoading();
      await actions.fetchById(bookId);
    } catch (e) {
      window.alert(`배송 조회에 실패했습니다. ${e.message}`);
    }

    loadingActions.finishLoading();
  };

  const fetchBaseAddressById = async () => {
    try {
      await baseAddressesActions.fetchAllById({
        bookId: delivery.bookId,
        buildingDongNumber: delivery.typeNormal
          ? delivery.receiverBuildingDongNumber
          : delivery.senderBuildingDongNumber,
        addressRoad: delivery.typeNormal
          ? delivery.receiverAddressRoad
          : delivery.senderAddressRoad,
        address: delivery.typeNormal
          ? delivery.receiverAddress
          : delivery.senderAddress,
      });
    } catch (e) {
      window.alert(`주소 정보 조회에 실패했습니다. ${e.message}`);
    }
  };

  const setPickup = async ({ path, location }) => {
    try {
      loadingActions.startLoading();

      await actions.setPickup({
        bookId,
        data: {
          image: `https://storage.googleapis.com/doobal-files${
            process.env.REACT_APP_VERSION_TYPE === "stable" ||
            process.env.REACT_APP_VERSION_TYPE === "canary"
              ? ""
              : "-dev"
          }/${path}`,
          pickedupLatitude: location.latitude,
          pickedupLongitude: location.longitude,
        },
      });

      goToPickups();
    } catch (e) {
      window.alert(`수거완료 처리에 실패했습니다. ${e.message}`);
    }

    loadingActions.finishLoading();
  };

  if (!delivery) {
    return <></>;
  }

  return (
    <div>
      {/** 주요 정보 */}
      <div className="bg-[#ffff00] p-10 grid gap-0">
        {(delivery.statusPickup || delivery.statusDelivery) && (
          <div className="flex justify-end items-center gap-5">
            {/** 편의점 바코드 */}
            {delivery.isConvenienceBarcode && (
              <ConvenienceBarcodeButton delivery={delivery} />
            )}

            {/** 재배차 처리 */}
            <ReallocationButton delivery={delivery} />

            {/** 픽/반품 수거건 취소 */}
            {delivery.statusPickup && delivery.typePickupReturn && (
              <CancelDeliveryPickupReturnButton delivery={delivery} />
            )}
            {/** 배송 지연 처리 */}
            {delivery.statusDelivery && (
              <DeliveryDelayButton
                delivery={delivery}
                onAfterSubmit={fetchById}
              />
            )}
          </div>
        )}

        {delivery.orderIdFromCorp && (
          <InfoRow>
            <InfoLabel>사측번호</InfoLabel>
            <InfoValue>{delivery.orderIdFromCorp}</InfoValue>
          </InfoRow>
        )}

        <InfoRow>
          <InfoLabel>고객요청</InfoLabel>
          <InfoValue>{delivery.memo || "없음"}</InfoValue>
        </InfoRow>

        {delivery.isLimitedHours && (
          <InfoRow>
            <InfoLabel>확인사항</InfoLabel>
            <InfoValue>
              {formatDate(delivery.limitedDeliveryAt, "HH시 mm분")}까지 도착
              보장
            </InfoValue>
          </InfoRow>
        )}

        {baseAddresses?.length > 0 && (
          <InfoRow>
            <InfoLabel>주소정보</InfoLabel>
            <InfoValue>{baseAddresses[0].memo}</InfoValue>
          </InfoRow>
        )}

        {delivery.isHighPrice && (
          <InfoRow>
            <InfoLabel>{!delivery.isLimitedHours && "확인사항"}</InfoLabel>
            <InfoValue>고객연락 및 확인 필수</InfoValue>
          </InfoRow>
        )}

        <InfoRow>
          <InfoLabel>아파트동</InfoLabel>
          <InfoValue>
            {delivery.typeNormal
              ? delivery.receiverBuildingDongNumber || "없음"
              : delivery.senderBuildingDongNumber || "없음"}
            ({delivery.renderAddressApiType})
          </InfoValue>
        </InfoRow>
      </div>

      {/** 현관 비밀번호 */}
      {delivery.frontdoorPassword && (
        <div className="p-10">
          <FrontdoorPasswordButton
            delivery={delivery}
            onAfterSubmit={fetchById}
          />
        </div>
      )}

      {/** 출발지 정보 */}
      <div className="p-10 text-gray-8">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center">
            <div className="text-20 text-black">출</div>
            {delivery.statusDelivery && delivery.typeNormal && (
              <div className="ml-5">
                {delivery.spotName}
                {delivery.spot?.name ? `(${delivery.senderName})` : ""}
              </div>
            )}
          </div>
          <div className="flex justify-end items-center">
            {delivery.statusPickup &&
              delivery.typePickupReturn &&
              delivery.senderMobile && (
                <SendMessageButton
                  delivery={delivery}
                  mobile={delivery.senderMobile}
                  type="pickupReturn"
                />
              )}

            {delivery.senderMobile && (
              <CallButton mobile={delivery.senderMobile} label="통화" />
            )}
          </div>
        </div>
        {!delivery.statusDelivery && (
          <div className="mt-10 grid gap-5">
            <div className="text-18">
              {delivery.spotName}
              {delivery.spot?.name ? `(${delivery.senderName})` : ""}
            </div>
            <div className="text-18">
              {delivery.senderAddressRoadWithoutSigungu}{" "}
              {delivery.senderBuilding}
            </div>
            <div className="text-18">
              {delivery.deliveryPlace && delivery.typePickupReturn && (
                <span className="font-bold text-white bg-orange mr-5">
                  보관등록
                </span>
              )}
              {delivery.senderAddress || delivery.senderAddressRoad || ""}
              {delivery.senderAddressDetail
                ? `, ${delivery.senderAddressDetail}`
                : ""}
            </div>

            <div className="flex justify-start items-center gap-5">
              {/** 위치보기 */}
              <DeliveryLocationButton type="sender" />
              {/** 주소정보 */}
              {delivery.typePickupReturn && (
                <BaseAddressButton
                  delivery={delivery}
                  type="sender"
                  onAfterSubmit={fetchBaseAddressById}
                />
              )}

              {delivery.statusPickup && delivery.typeNormal && (
                <PickupButton delivery={delivery} />
              )}
            </div>

            {/** 픽반품 수거 완료 */}
            {delivery.statusPickup && delivery.typePickupReturn && (
              <PickupCompleteComponent
                delivery={delivery}
                onAfterSubmit={fetchById}
              />
            )}

            {/** 주소 수정 */}
            {delivery.statusPickup && delivery.typePickupReturn && (
              <div className="flex justify-start items-center gap-5">
                <UpdateAddressButton
                  delivery={delivery}
                  type="sender"
                  onAfterSubmit={fetchById}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {/** divide */}
      <div className="h-1 bg-gray-d" />

      {/** 도착지 정보 */}
      <div className="p-10 text-gray-8">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center">
            <div className="text-20 text-black">도</div>
            {delivery.isHighPrice && (
              <div className="bg-orange w-16 h-16 rounded-full ml-5" />
            )}
          </div>
          <div className="flex justify-end items-center">
            {delivery.statusDeliveryCompleted &&
              (delivery.receiverSmsMobile || delivery.receiverMobile) && (
                <UpdateDeliveryCompletedLocationButton
                  delivery={delivery}
                  onAfterSubmit={fetchById}
                />
              )}
            {(delivery.receiverSmsMobile || delivery.receiverMobile) && (
              <SendMessageButton
                mobile={delivery.receiverSmsMobile || delivery.receiverMobile}
                type="receiver"
              />
            )}
            {delivery.receiverMobile && (
              <CallButton mobile={delivery.receiverMobile} label="전화1" />
            )}
            {delivery.receiverMobile2 && (
              <CallButton mobile={delivery.receiverMobile2} label="전화2" />
            )}
          </div>
        </div>
        <div className="mt-10">
          <div className="flex justify-between items-center mb-5">
            <div className="text-18">{delivery.receiverName}</div>
            <div className="flex justify-end items-center">
              {delivery.statusDeliveryStart && delivery.typeNormal && (
                <DeliveryLostButton
                  delivery={delivery}
                  onAfterSubmit={fetchById}
                />
              )}
              {delivery.statusDeliveryStart && delivery.typeNormal && (
                <DeliverySentBackButton
                  delivery={delivery}
                  onAfterSubmit={fetchById}
                />
              )}
            </div>
          </div>
          <div className="mt-5 grid gap-5">
            {delivery.receiverPoint ? (
              <div className="text-18">
                {delivery.soojioneLimitedCustomized && (
                  <span className="font-bold text-white bg-orange mr-5">
                    주의{delivery.distanceRefineToLimitedRefined}
                  </span>
                )}
                {(delivery.spotKurlyDrink || delivery.mustSignature) && (
                  <span className="font-bold text-white bg-orange mr-5">
                    서명
                  </span>
                )}
                {delivery.receiverAddressRoadWithoutSigungu ||
                  delivery.receiverAddressWithoutSigungu ||
                  ""}{" "}
                {delivery.receiverBuilding}
                {delivery.receiverAddressRoadWithoutSigungu &&
                delivery.receiverAddressWithoutSigungu
                  ? `/${delivery.receiverAddressWithoutSigungu}`
                  : ""}
                {!delivery.receiverAddressRoadWithoutSigungu &&
                delivery.receiverDong
                  ? `/${delivery.receiverDong}`
                  : ""}
              </div>
            ) : (
              <div className="flex justify-start items-center gap-5 mt-10">
                <ResetAddressButton
                  delivery={delivery}
                  type="receiver"
                  onAfterSubmit={fetchById}
                />
              </div>
            )}
            <div className="text-18">{delivery.displayAddress}</div>
            {delivery.customerAddressEngGpt && (
              <div className="text-black font-bold text-18">
                (GPT번역) {delivery.customerAddressEngGpt}
              </div>
            )}
            {delivery.customerAddressEng && (
              <div className="text-18">
                (번역주의) {delivery.customerAddressEng}
              </div>
            )}

            {/** 배송 대기 상태  */}
            {delivery.typeNormal && delivery.statusWaiting && (
              <ButtonsWrap>
                <DeliveryPostponedButton
                  delivery={delivery}
                  onAfterSubmit={fetchById}
                />
                <DeliveryStartButton
                  delivery={delivery}
                  onAfterSubmit={fetchById}
                />
              </ButtonsWrap>
            )}

            {/** 배송 완료후 사진 업로드 실패 */}
            {delivery.statusDeliveryCompleted && !delivery.completedImage && (
              <>
                <div className="text-red">
                  사진 등록이 실패했습니다. 다시 등록해주십시오.
                </div>
                <div className="flex justify-start items-center gap-5">
                  <button
                    type="button"
                    className="bg-orange text-white py-4 px-12 flex-1"
                  >
                    재촬영
                  </button>
                  <button
                    type="button"
                    className="bg-orange text-white py-4 px-12 flex-1"
                  >
                    사진선택
                  </button>
                </div>
              </>
            )}

            {/** 배송 완료 처리 */}
            {(delivery.statusDeliveryStart ||
              delivery.statusDeliveryPostponed) && (
              <DeliveryCompleteComponent
                delivery={delivery}
                onAfterSubmit={fetchById}
              />
            )}

            {/** 위치 / 주소정보 */}
            {(delivery.statusPickup || delivery.statusDelivery) && (
              <div className="flex justify-start items-center gap-5">
                {/** 위치보기 */}
                <DeliveryLocationButton type="receiver" />
                {/** 주소정보 */}
                {delivery.typeNormal && (
                  <BaseAddressButton
                    delivery={delivery}
                    type="receiver"
                    onAfterSubmit={fetchBaseAddressById}
                  />
                )}
                <DeliveryRouteButton delivery={delivery} />
              </div>
            )}

            {/** 주소 수정 */}
            {delivery.statusDelivery && (
              <div className="flex justify-start items-center gap-5">
                <UpdateAddressButton
                  delivery={delivery}
                  type="receiver"
                  onAfterSubmit={fetchById}
                />
              </div>
            )}

            {/** 직접등록건 취소 */}
            {delivery.statusDeliveryStart && delivery.nonDoobalType && (
              <div className="flex justify-start items-center gap-5">
                <CancelDeliveryButton delivery={delivery} />
              </div>
            )}

            {/** 배송불가 취소처리 */}
            {delivery.cancelableAddressNotSupported && (
              <ButtonsWrap>
                <CancelDeliveryNotSupportedButton
                  delivery={delivery}
                  onAfterSubmit={fetchById}
                />
              </ButtonsWrap>
            )}

            {/** 배송 완료 상태 */}
            {delivery.statusDeliveryCompleted && (
              <>
                <ButtonsWrap>
                  {delivery.deliveryDoneLatitude &&
                    delivery.deliveryDoneLongitude && (
                      <DeliveryCompletedLocationButton
                        bgColor="bg-mint"
                        flex={2}
                        completedLatitude={delivery.deliveryDoneLatitude}
                        completedLongitude={delivery.deliveryDoneLongitude}
                        label="주소위치/완료위치"
                      />
                    )}
                  {/** 주소정보 */}
                  {delivery.typeNormal && (
                    <BaseAddressButton
                      delivery={delivery}
                      type="receiver"
                      onAfterSubmit={fetchBaseAddressById}
                    />
                  )}
                </ButtonsWrap>
                <ButtonsWrap>
                  <CancelDeliveryCompletedButton
                    delivery={delivery}
                    label="배송완료"
                    onAfterSubmit={fetchById}
                  />
                  {failedFile && (
                    <button
                      type="button"
                      className="bg-orange text-white py-4 px-12 flex-1"
                    >
                      파일재업로드
                    </button>
                  )}
                  {!failedFile && delivery.completedImage && (
                    <DeliveryCompletedImageButton
                      completedImage={delivery.completedImage}
                    />
                  )}
                </ButtonsWrap>
              </>
            )}

            {/** 반송 완료 상태 */}
            {delivery.statusDeliverySentBack && (
              <ButtonsWrap>
                <CancelDeliveryCompletedButton
                  delivery={delivery}
                  label="반송완료"
                  onAfterSubmit={fetchById}
                />
                {delivery.deliverySentBackLatitude &&
                  delivery.deliverySentBackLongitude && (
                    <DeliveryCompletedLocationButton
                      completedLatitude={delivery.deliverySentBackLatitude}
                      completedLongitude={delivery.deliverySentBackLongitude}
                      label="주소위치/반송위치"
                    />
                  )}
              </ButtonsWrap>
            )}

            {/** 분실 완료 상태 */}
            {delivery.statusDeliveryLost && (
              <ButtonsWrap>
                <CancelDeliveryCompletedButton
                  delivery={delivery}
                  label="분실완료"
                  onAfterSubmit={fetchById}
                />
                {delivery.deliveryLostLatitude &&
                  delivery.deliveryLostLongitude && (
                    <DeliveryCompletedLocationButton
                      completedLatitude={delivery.deliveryLostLatitude}
                      completedLongitude={delivery.deliveryLostLongitude}
                      label="주소위치/분실위치"
                    />
                  )}
              </ButtonsWrap>
            )}
          </div>
        </div>
      </div>

      {/** divide */}
      <div className="h-1 bg-gray-d" />

      {/** 송하인 정보 */}
      <div className="p-10 text-gray-8">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center">
            <div className="text-20 text-black">송하인</div>
          </div>
        </div>
        <div className="mt-10">
          <div className="flex justify-between items-center mb-5">
            <div className="text-18">{delivery.senderName}</div>
            <div className="flex justify-end items-center">
              {delivery.senderMobile && (
                <CallButton mobile={delivery.senderMobile} label="통화" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Delivery;
