/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Modal from "../components/Modal";

import { useModalStore } from "../stores/hooks";

import { SMS_MESSAGES } from "../utils/message";
import { POST_MESSAGES_TYPES } from "../utils/postMessages";

const MessageItem = ({ active, message, onSelect }) => {
  const handleSelect = () => {
    onSelect(message);
  };

  return (
    <div>
      <label className="block mb-10" onClick={handleSelect}>
        <input
          type="radio"
          name="corp"
          defaultChecked={!!active}
          className="mr-5"
        />
        {message}
      </label>
    </div>
  );
};

function SendMessageModal({ mobile, type }) {
  const { closeModal } = useModalStore();

  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const [load, setLoad] = useState(false);

  useEffect(() => {
    reset({
      message: SMS_MESSAGES[0],
    });

    setLoad(true);
  }, []);

  const handleSelectMessage = (message) => {
    setValue("message", message);
  };

  const handleCancel = () => {
    closeModal();
  };

  const onSubmit = ({ message }) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: POST_MESSAGES_TYPES.SEND_MESSAGE,
          mobile,
          message,
        }),
      );

      closeModal();
    } else {
      window.alert("문자 발송에 실패했습니다. 다시 시도해주세요");
    }
  };

  return (
    <Modal>
      <div className="p-15">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-20">
            <h5 className="font-medium text-18 mb-10">{mobile}</h5>
            <div>
              {load &&
                SMS_MESSAGES.map((message) => {
                  return (
                    <MessageItem
                      key={message}
                      active={message === watch("message")}
                      message={message}
                      onSelect={handleSelectMessage}
                    />
                  );
                })}
              <input name="message" {...register("message")} hidden />
            </div>
          </div>
          <div className="flex justify-center items-center">
            <button
              type="button"
              className="flex-1 p-10"
              onClick={handleCancel}
            >
              닫기
            </button>
            <button type="submit" className="text-orange flex-1 p-10">
              보내기
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default SendMessageModal;
