/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Modal from "../components/Modal";

import {
  useDeliveriesStore,
  useLoadingStore,
  useModalStore,
} from "../stores/hooks";

import {
  COMPLETED_LOCATIONS,
  COMPLETED_LOCATION_POSTBOX,
} from "../utils/delivery";
import {
  sendToastMessage,
  sendSmsMessageForDirect,
} from "../utils/postMessages";

const LocationItem = ({ active, location, onSelect }) => {
  const handleSelect = () => {
    let body = {
      code: location.code,
      text: location.text,
    };

    onSelect(body);
  };

  return (
    <div>
      <label className="block mb-10" onClick={handleSelect}>
        <input
          type="radio"
          name="corp"
          defaultChecked={!!active}
          className="mr-5"
        />
        {location.text}
      </label>
    </div>
  );
};

function UpdateCompletedLocationModal({
  delivery,
  cancelable = false,
  onAfterSubmit,
}) {
  const { ...actions } = useDeliveriesStore();

  const { ...loadingActions } = useLoadingStore();

  const { closeModal } = useModalStore();

  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const [load, setLoad] = useState(false);

  useEffect(() => {
    reset({
      code: COMPLETED_LOCATIONS[0].code,
    });

    setLoad(true);
  }, []);

  const handleSelectLocation = ({ code, text }) => {
    setValue("code", code);
    setValue("text", text);
    setValue("postboxPassword", "");
  };

  const handleDeletePostboxPassword = () => {
    setValue("postboxPassword", "");
  };

  const handleCancel = () => {
    closeModal();
  };

  const onSubmit = async ({ code, text, postboxPassword }) => {
    try {
      loadingActions.startLoading();

      let sendedCompletedSms = false;

      if (delivery.receiverSmsMobile || delivery.receiverMobile) {
        sendedCompletedSms = true;
      }

      // etc4에 배송 위치 저장
      const { urlToCheckCompletedDelivery } = await actions.setDeliveryLocation(
        {
          bookId: delivery.bookId,
          data: {
            completedLocation: `${text}${
              code === COMPLETED_LOCATION_POSTBOX && postboxPassword
                ? `(${postboxPassword})`
                : ""
            }`,
            completedLocationCode: code,
            sendedCompletedSms,
            alimtalk: false,
          },
        },
      );

      if (sendedCompletedSms) {
        let message = `[두발히어로]배송완료했습니다(${text}${
          code === COMPLETED_LOCATION_POSTBOX && postboxPassword
            ? `(${postboxPassword})`
            : ""
        })\n완료사진 ${urlToCheckCompletedDelivery}`;

        if (!urlToCheckCompletedDelivery) {
          message = `[두발히어로]배송완료했습니다(${text}${
            code === COMPLETED_LOCATION_POSTBOX && postboxPassword
              ? `(${postboxPassword})`
              : ""
          })`;
        }

        sendSmsMessageForDirect({
          mobile: delivery.receiverSmsMobile || delivery.receiverMobile,
          message,
        });

        sendToastMessage("배송완료 메시지를 발송했습니다.");
      } else {
        sendToastMessage("배송 완료 위치를 저장했습니다.");
      }

      onAfterSubmit();
      closeModal();
    } catch (e) {
      window.alert(`배송건 토큰 발급에 실패했습니다. ${e.message}`);
    }
  };

  return (
    <Modal>
      <div className="p-15">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-20">
            <h5 className="font-medium text-18 mb-10">배송 위치 선택</h5>
            <div>
              {load &&
                COMPLETED_LOCATIONS.map((location) => {
                  let active = location.code === watch("code");

                  return (
                    <div key={location.code}>
                      <LocationItem
                        active={active}
                        location={location}
                        onSelect={handleSelectLocation}
                      />
                      {location.code === COMPLETED_LOCATION_POSTBOX && (
                        <div className="ml-10 mb-10">
                          <div className="bg-[#f6f6f6] p-5 flex justify-between items-center">
                            <input
                              type="text"
                              className="bg-transparent max-w-[calc(100vw-120px)] flex-1"
                              disabled={!active}
                              maxLength={10}
                              {...register("postboxPassword")}
                              placeholder="택배함 비밀번호"
                            />
                            <button
                              type="button"
                              className="text-[#999] p-5 w-50"
                              onClick={handleDeletePostboxPassword}
                            >
                              삭제
                            </button>
                          </div>
                          <div className="text-11 text-gray-a mt-5">
                            특수문자 &#123;, &#125;, &#34;는 사용할 수 없습니다.
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              <input name="code" {...register("code")} hidden />
              <input name="text" {...register("text")} hidden />
            </div>
          </div>
          <div className="flex justify-center items-center">
            {cancelable && (
              <button
                type="button"
                className="flex-1 p-10"
                onClick={handleCancel}
              >
                닫기
              </button>
            )}

            <button type="submit" className="text-orange flex-1 p-10">
              확인
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default UpdateCompletedLocationModal;
