/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Modal from "../components/Modal";

import {
  useDeliveriesStore,
  useLoadingStore,
  useModalStore,
  useRidersStore,
} from "../stores/hooks";

import { mobileDash } from "../utils/common";
import { goToDeliveries, goToPickups } from "../utils/postMessages";

import AlertModal from "./AlertModal";
import ConfirmModal from "./ConfirmModal";

import { riderId } from "../stores/Api";

const RiderItem = ({ active, rider, onSelect }) => {
  const { state, ...actions } = useRidersStore();

  const favoratedIndex = state.favorateRiders?.findIndex(
    (favo) => favo.id === rider.id,
  );

  const handleSelect = () => {
    onSelect(rider);
  };

  const handleToggleFavorate = () => {
    if (favoratedIndex > -1) {
      actions.setFavorateRider({ type: "UNSET", favoratedIndex });
    } else {
      let _rider = {
        id: rider.id,
        name: rider.name,
        mobile1: rider.mobile1,
      };

      actions.setFavorateRider({ type: "SET", favorateRider: _rider });
    }
  };

  return (
    <div className="flex pt-5 justify-start items-center">
      <div className="mr-10">
        <button type="button" onClick={handleToggleFavorate}>
          {favoratedIndex > -1 ? (
            <img src="/images/star-on.png" alt="즐겨찾기 중" className="w-24" />
          ) : (
            <img src="/images/star.png" alt="즐겨찾기" className="w-24" />
          )}
        </button>
      </div>
      <div
        className="flex justify-start items-center flex-1 cursor-pointer"
        tabIndex={0}
        onClick={handleSelect}
      >
        <div className="w-80">{rider.name}</div>
        <div className="flex-1">{mobileDash(rider.mobile1)}</div>
        {active ? (
          <img src="/images/checkbox-on.png" alt="선택됨" className="w-30" />
        ) : (
          <img src="/images/checkbox.png" alt="선택안됨" className="w-30" />
        )}
      </div>
    </div>
  );
};

const Search = () => {
  const { state, ...actions } = useRidersStore();

  const { register, handleSubmit, reset, setValue } = useForm();

  useEffect(() => {
    reset({
      text: state.query.text,
    });
  }, []);

  const handleDeleteText = () => {
    setValue("text", "");
  };

  const onSubmit = ({ text }) => {
    actions.setQuery({
      text,
      page: 1,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-10">
      <div className="flex justify-start items-center ">
        <div className="flex-1 flex justify-start items-center bg-gray-e">
          <input
            type="text"
            className="bg-transparent h-34 px-5 flex-1"
            {...register("text")}
            maxLength={10}
          />
          <button
            type="button"
            className="text-gray-8 p-5 w-50"
            onClick={handleDeleteText}
          >
            삭제
          </button>
        </div>
        <button type="submit" className="text-white bg-orange p-5 w-50">
          검색
        </button>
      </div>
    </form>
  );
};

function ReallocationRidersModal({ delivery }) {
  const { ...deliveriesActions } = useDeliveriesStore();

  const { ...loadingActions } = useLoadingStore();

  const { openModal, closeModal } = useModalStore();

  const { state, ...actions } = useRidersStore();

  const [selectedRider, setSelectedRider] = useState(null);

  const [filteredRiders, setFilteredList] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    fetchFavorateRiders();
  }, []);

  useEffect(() => {
    if (state.query?.page) {
      fetchRiders();
    }
  }, [state.query]);

  useEffect(() => {
    if (!showAll && !!state.favorateRiders.length) {
      setFilteredList([...state.favorateRiders]);
    } else {
      setFilteredList([
        ...state.favorateRiders,
        ...state.riders.filter(
          (ri) =>
            !state.favorateRiders.some((fari) => fari.id === ri.id) &&
            Number(ri.id) !== Number(riderId),
        ),
      ]);
    }
  }, [state.riders, state.favorateRiders, riderId, showAll]);

  const fetchRiders = async () => {
    try {
      loadingActions.startLoading();

      await actions.fetchAll();
    } catch (e) {
      window.alert(`라이더 목록 조회에 실패했습니다. ${e.message}`);
    }

    loadingActions.finishLoading();
  };

  const fetchFavorateRiders = async () => {
    try {
      await actions.fetchFavorateRiders();
    } catch (e) {
      window.alert(`라이더 즐겨찾기 목록 조회에 실패했습니다. ${e.message}`);
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleSelectRider = (rider) => {
    setSelectedRider(rider);
  };

  const nextPage = () => {
    if (!showAll && !!state.favorateRiders.length) {
      setShowAll(true);
    } else {
      actions.setQuery({
        page: state.query.page + 1,
      });
    }
  };

  const confirmReallocation = () => {
    if (selectedRider) {
      const message = `${delivery.bookId} ${
        delivery.statusDeliveryStart
          ? `${delivery.displayAddress}`
          : `${delivery.displaySenderAddress}`
      } ${delivery.statusStringForReallocation}건을 ${
        selectedRider.name
      }(${mobileDash(
        selectedRider.mobile1,
      )}) 기사에게 재배차하시겠습니까? 재배차 완료된 접수건은 지정한 배송기사에게 바로 이전됩니다.`;
      openModal(
        <ConfirmModal
          key="confirm-modal"
          message={message}
          onSubmit={onSubmit}
        />,
      );
    }
  };

  const onSubmit = async () => {
    if (selectedRider) {
      try {
        loadingActions.startLoading();

        const data = {
          type: delivery.statusPickup ? "1" : "2",
          reallocatedRiderId: selectedRider.id,
        };

        await deliveriesActions.reallocate({
          bookId: delivery.bookId,
          data,
        });

        openModal(
          <AlertModal
            key="alert-modal"
            message={`${delivery.statusStringForReallocation} 재배차 요청 완료`}
            onSubmit={delivery.statusPickup ? goToPickups : goToDeliveries}
          />,
        );
      } catch (e) {
        window.alert(`재배차 처리에 실패했습니다. ${e.message}`);
      }

      loadingActions.finishLoading();
    } else {
      window.alert("라이더를 선택해주세요.");
    }
  };

  return (
    <Modal>
      <div className="p-15">
        <div className="mb-20">
          <h5 className="font-medium text-18 mb-10">
            {delivery.statusStringForReallocation} 재배차 기사 선택
          </h5>
          <div>
            <Search />
            <div className="max-h-[calc(100vh-300px)] overflow-auto grid gap-5 divide-y-1 divide-gray-d">
              {filteredRiders?.map((rider) => (
                <RiderItem
                  key={rider.id}
                  active={rider.id === selectedRider?.id}
                  rider={rider}
                  onSelect={handleSelectRider}
                />
              ))}
            </div>
            {((!showAll && !!state.favorateRiders.length) ||
              state.query?.page < state.pageCount) && (
              <button
                className="bg-orange text-white text-16 font-bold rounded-6 w-full p-4 mt-10"
                type="button"
                onClick={nextPage}
              >
                더보기
              </button>
            )}
          </div>
        </div>
        <div className="flex justify-center items-center">
          <button type="button" className="flex-1 p-10" onClick={handleCancel}>
            취소
          </button>
          <button
            type="button"
            className="text-orange flex-1 p-10"
            onClick={confirmReallocation}
          >
            재배차요청
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ReallocationRidersModal;
