export const CORP_HANJINAPI = {
  displayName: "한진(아마존)",
  senderName: "한진(배송원직접추가)",
  corpUser: "hanjinapi",
  spotCode: "10480",
};

export const CORP_OLIVEYOUNG = {
  displayName: "올리브영",
  senderName: "올리브영",
  corpUser: "cjoyapi",
  spotCode: "",
};

export const CORP_ETC = {
  displayName: "직접입력",
  senderName: "",
  corpUser: "직접입력",
  spotCode: "직접입력",
};

export const CORP_OLIVEYOUNG_MFC = [
  {
    name: "경기군포MFC",
    spotCode: "10499",
  },
  {
    name: "경기부천MFC",
    spotCode: "10540",
  },

  {
    name: "경기성남MFC",
    spotCode: "10520",
  },
  {
    name: "경북경주MFC",
    spotCode: "10579",
  },
  {
    name: "부산해운대MFC",
    spotCode: "10549",
  },
  {
    name: "부산사상MFC",
    spotCode: "10561",
  },
  {
    name: "서울강남MFC",
    spotCode: "10427",
  },
  {
    name: "서울관악MFC",
    spotCode: "10332",
  },
  {
    name: "서울성북MFC",
    spotCode: "10426",
  },
  {
    name: "인천MFC",
    spotCode: "10576",
  },
  {
    name: "전북전주MFC",
    spotCode: "10574",
  },
];
