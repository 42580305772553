/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import Modal from "../components/Modal";

import UpdateAddressDetailModal from "./UpdateAddressDetailModal";

import {
  useDeliveriesStore,
  useLoadingStore,
  useModalStore,
} from "../stores/hooks";

function load(url, cb, err) {
  let element = document.createElement("script");

  element.async = true;
  element.onload = cb;
  element.onerror = err;

  element["src"] = url;
  document["head"].appendChild(element);
}

function UpdateAddressModal({
  delivery,
  type = "receiver",
  updateType = "update",
  onAfterSubmit,
}) {
  const { ...actions } = useDeliveriesStore();

  const { ...loadingActions } = useLoadingStore();

  const { openModal, closeModal } = useModalStore();

  useEffect(() => {
    load(
      "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js",
      drawPostcode,
    );
  }, []);

  const drawPostcode = () => {
    const element_wrap = document.getElementById("wrap");

    new window.daum.Postcode({
      oncomplete: handleCompleteDaumPostcode,
      width: "100%",
      height: "100%",
    }).embed(element_wrap, { autoClose: false });
  };

  const handleCompleteDaumPostcode = (data) => {
    let roadAddress = data.roadAddress; // 도로명주소
    // let jibunAddress = data.jibunAddress || data.autoJibunAddress; // 지번주소
    let extraAddress = ""; // 참고항목 변수
    // let zonecode = data.zonecode;

    // 법정동명이 있을 경우 추가한다. (법정리는 제외)
    // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
    if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
      extraAddress += data.bname;
    }
    // 건물명이 있고, 공동주택일 경우 추가한다.
    if (data.buildingName !== "" && data.apartment === "Y") {
      extraAddress +=
        extraAddress !== "" ? ", " + data.buildingName : data.buildingName;
    }
    // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
    if (extraAddress !== "") {
      extraAddress = " (" + extraAddress + ")";
    }

    openModal(
      <UpdateAddressDetailModal
        key="update-address-detail-modal"
        address={roadAddress + extraAddress}
        displayAddress={
          type === "sender"
            ? delivery.displaySenderAddress
            : delivery.displayAddress
        }
        onFinalSubmit={onSubmit}
      />,
    );
  };

  const handleCancel = () => {
    closeModal();
  };

  const onSubmit = async (data) => {
    try {
      loadingActions.startLoading();

      const _data = {
        ...data,
        type,
      };

      await actions.updateAddress({
        bookId: delivery.bookId,
        data: _data,
      });

      onAfterSubmit();
      closeModal();
    } catch (e) {
      window.alert(
        `${
          updateType === "reset" ? "위치 등록" : "주소 수정"
        }에 실패했습니다. ${e.message}`,
      );
    }

    loadingActions.finishLoading();
  };

  return (
    <Modal>
      <div className="py-15">
        <div className="">
          <h5 className="font-medium text-18 mb-10 px-15">
            {updateType === "reset" ? "위치 등록(필수)" : "주소 수정"}
          </h5>
          <div className="px-15 bg-gray-d py-5">
            <div>
              {type === "sender"
                ? delivery.displaySenderAddress
                : delivery.displayAddress}
            </div>
            {type === "receiver" && (
              <>
                {delivery.customerAddressEngGpt && (
                  <div>{delivery.customerAddressEngGpt}</div>
                )}
                {delivery.customerAddressEng && (
                  <div>{delivery.customerAddressEng}</div>
                )}
              </>
            )}
          </div>
          <div id="wrap" className="w-full h-[400px] overflow-hidden" />
        </div>
        <div className="flex justify-center items-center px-15">
          <button type="button" className="flex-1 p-10" onClick={handleCancel}>
            취소
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default UpdateAddressModal;
