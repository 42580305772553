/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

import api from "../Api";

import { CORP_HANJINAPI } from "../../utils/corps";

export const NEW_DELIVERY_TYPE_DOCUMENT = "DOCUMENT";
export const NEW_DELIVERY_TYPE_POSTBOX = "POSTBOX";

const deliveriesNewAtom = atom({
  key: "deliveriesNew",
  default: {
    address: null,
    addressDetail: null,
    displayName: CORP_HANJINAPI.displayName,
    senderName: CORP_HANJINAPI.senderName,
    corpUser: CORP_HANJINAPI.corpUser,
    spotCode: CORP_HANJINAPI.spotCode,
    additionalFee: false,
    type: NEW_DELIVERY_TYPE_DOCUMENT,
  },
});

export const useDeliveriesNewStore = () => {
  const [body, setBodyState] = useRecoilState(deliveriesNewAtom);

  const create = useCallback(
    async (data) => {
      return await api.post(`/deliveries/non-doobal-deliveries`, data);
    },
    [api.post],
  );

  const setBody = (_body) => {
    setBodyState({
      ...body,
      ..._body,
    });
  };

  const state = {
    body,
  };

  return {
    state,
    create,
    setBody,
  };
};
