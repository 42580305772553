/**
 * 문자 발송
 */
export const SMS_MESSAGES = [
  "가장 빠른 오늘도착 택배 두발히어로/인타임퀵에서 배송 도착했습니다.",
  "가장 빠른 오늘도착 택배 두발히어로/인타임퀵 – 경비실에 물품을 보관하였습니다.",
  "가장 빠른 오늘도착 택배 두발히어로/인타임퀵 – 현관 문 앞 보관하였습니다.",
  "가장 빠른 오늘도착 택배 두발히어로/인타임퀵 – 우편함에 보관하였습니다.",
  "가장 빠른 오늘도착 택배 두발히어로/인타임퀵 – 수령인 부재로 배송하지 못했습니다. 다음 회차에 다시 배송하겠습니다.",
];
