/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

import api, { riderId } from "../Api";

import BaseAddress from "../../models/BaseAddress";

const baseAddressesAtom = atom({
  key: "baseAddresses",
  default: null,
});

const baseAddressesObjAtom = atom({
  key: "baseAddressesObj",
  default: {
    currentBuildingDong: [],
    buildingDongWithAddressRoad: [],
    buildingDongWithAddress: [],
    noBuildingDongWithAddressRoad: [],
    noBuildingDongWithAddress: [],
  },
});

const baseAddressesAllAtom = atom({
  key: "baseAddressesAll",
  default: null,
});

const baseAddressIdAtom = atom({
  key: "baseAddressId",
  default: null,
});

export const useBaseAddressesStore = () => {
  const [baseAddresses, setBaseAddresses] = useRecoilState(baseAddressesAtom);
  const [baseAddressesAll, setBaseAddressesAll] =
    useRecoilState(baseAddressesAllAtom);
  const [baseAddressesObj, setBaseAddressesObj] =
    useRecoilState(baseAddressesObjAtom);
  const [baseAddressId, setBaseAddressId] = useRecoilState(baseAddressIdAtom);

  const create = useCallback(
    async (data) => await api.post(`/base-addresses/memos`, data),
    [api.post],
  );

  const deleteBaseAddress = useCallback(
    async (id) => {
      return await api.delete(`/base-addresses/memos/${id}`);
    },
    [api.delete],
  );

  const fetchAllById = useCallback(
    async ({ bookId, buildingDongNumber, address, addressRoad }) => {
      if (bookId) {
        const { noBuildingDong, withBuildingDong } = await api.get(
          `/deliveries/${bookId}/base-addresses/memos/all`,
        );

        let _currentBuildingDong = []; // 도로명 주소 일치 / 빌딩동 정보 일치
        let _withBuildingDongWithAddressRoad = []; // 도로명 주소 일치 / 빌딩동 정보 불일치
        let _withBuildingDongWithAddress = []; // 도로명 주소 불일치
        let _noBuildingDongWithAddressRoad = []; // 도로명 주소 일치
        let _noBuildingDongWithAddress = []; // 도로명 주소 불일치

        if (withBuildingDong?.length > 0) {
          withBuildingDong.forEach((el) => {
            if (
              `${el.buildingDongNumber}동` === buildingDongNumber &&
              addressRoad === el.baseAddress?.addressRoad
            ) {
              _currentBuildingDong.push(el);
            } else if (addressRoad === el.baseAddress?.addressRoad) {
              _withBuildingDongWithAddressRoad.push(el);
            } else if (
              address === el.baseAddress?.address ||
              address + "번지" === el.baseAddress?.address ||
              address === el.baseAddress?.address + "번지"
            ) {
              _withBuildingDongWithAddress.push(el);
            }
          });
        }

        if (noBuildingDong?.length > 0) {
          noBuildingDong.forEach((el) => {
            if (addressRoad === el.baseAddress?.addressRoad) {
              _noBuildingDongWithAddressRoad.push(el);
            } else if (
              address === el.baseAddress?.address ||
              address + "번지" === el.baseAddress?.address ||
              address === el.baseAddress?.address + "번지"
            ) {
              _noBuildingDongWithAddress.push(el);
            }
          });
        }

        let baseAddresses = [
          ..._currentBuildingDong,
          ..._withBuildingDongWithAddressRoad,
          ..._noBuildingDongWithAddressRoad,
          ..._withBuildingDongWithAddress,
          ..._noBuildingDongWithAddress,
          ...noBuildingDong,
        ];

        setBaseAddresses(baseAddresses.map((b) => new BaseAddress(b)));
        setBaseAddressesAll([...noBuildingDong, ...withBuildingDong]);
        setBaseAddressesObj({
          currentBuildingDong: _currentBuildingDong.map(
            (b) => new BaseAddress(b),
          ),
          buildingDongWithAddressRoad: _withBuildingDongWithAddressRoad.map(
            (b) => new BaseAddress(b),
          ),
          buildingDongWithAddress: _withBuildingDongWithAddress.map(
            (b) => new BaseAddress(b),
          ),
          noBuildingDongWithAddressRoad: _noBuildingDongWithAddressRoad.map(
            (b) => new BaseAddress(b),
          ),
          noBuildingDongWithAddress: _noBuildingDongWithAddress.map(
            (b) => new BaseAddress(b),
          ),
        });
      }
    },
    [api.get, riderId],
  );

  const fetchBaseAddressId = useCallback(
    async ({ addressRoad }) => {
      const { items } = await api.get(`/base-addresses`, {
        page: 1,
        pageSize: 1000,
        addressRoad,
      });

      const ordereds = items.sort((a, b) => {
        return a.id > b.id ? -1 : 1;
      });

      setBaseAddressId(ordereds[0]?.id);

      return ordereds[0]?.id;
    },
    [api.get, riderId],
  );

  /**
   * 주소 정보 수정
   */
  const update = useCallback(
    async (id, { memo, baseAddressId, buildingDongNumber }) => {
      let data = { memo };

      if (baseAddressId) {
        data.baseAddressId = baseAddressId;
      }

      if (buildingDongNumber) {
        data.buildingDongNumber = buildingDongNumber;
      }

      return await api.put(`/base-addresses/memos/${id}`, data);
    },
    [api.put],
  );

  const updateOrder = useCallback(
    async (id, data) => {
      return await api.put(`/base-addresses/memos/${id}/order`, data);
    },
    [api.put],
  );

  const state = {
    baseAddresses,
    baseAddressesAll,
    baseAddressesObj,
    baseAddressId,
  };

  return {
    state,
    create,
    deleteBaseAddress,
    fetchAllById,
    fetchBaseAddressId,
    update,
    updateOrder,
  };
};
