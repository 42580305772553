/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Modal from "../components/Modal";

import {
  useBaseAddressesStore,
  useLoadingStore,
  useModalStore,
} from "../stores/hooks";

import { sendToastMessage } from "../utils/postMessages";

const FrontdoorPasswordItem = ({
  active,
  frontdoorPassword,
  type = "client",
  onSelect,
}) => {
  const handleSelect = () => {
    onSelect(frontdoorPassword);
  };

  return (
    <div>
      <label className="block mb-10" onClick={handleSelect}>
        <input
          type="radio"
          name="checkbox"
          defaultChecked={!!active}
          className="mr-5"
        />
        [{type === "client" ? "고객" : "업체"}입력] {frontdoorPassword}
      </label>
    </div>
  );
};

function CreateBaseAddressByFrontdoorPasswordModal({
  delivery,
  onAfterSubmit,
}) {
  const { ...actions } = useBaseAddressesStore();

  const { ...loadingActions } = useLoadingStore();

  const { closeModal } = useModalStore();

  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const [load, setLoad] = useState(false);

  useEffect(() => {
    reset({
      frontdoorPassword:
        delivery.frontdoorPasswordByClient || delivery.frontdoorPasswordByCorp,
    });

    setLoad(true);
  }, []);

  const handleCancel = () => {
    closeModal();
  };

  const handleSelectFrontdoorPassoword = (frontdoorPassword) => {
    setValue("frontdoorPassword", frontdoorPassword);
  };

  const onSubmit = async ({ frontdoorPassword }) => {
    try {
      loadingActions.startLoading();

      const data = {
        bookId: delivery.bookId,
        memo: frontdoorPassword,
      };

      await actions.create(data);

      sendToastMessage(
        `아파트동 ${
          delivery.receiverBuildingDongNumber || "누락"
        }, 주소정보 ${frontdoorPassword} 등록했습니다.`,
      );

      onAfterSubmit();
      closeModal();

      onAfterSubmit();
      closeModal();
    } catch (e) {
      window.alert(`주소 정보 추가에 실패했습니다. ${e.message}`);
    }

    loadingActions.finishLoading();
  };

  return (
    <Modal>
      <div className="p-15">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-20">
            <h5 className="font-medium text-18 mb-10">
              주소 정보를 추가하시겠습니까?
            </h5>
            <div>
              {load && (
                <>
                  {delivery.frontdoorPasswordByClient && (
                    <FrontdoorPasswordItem
                      active={
                        delivery.frontdoorPasswordByClient ===
                        watch("frontdoorPassword")
                      }
                      frontdoorPassword={delivery.frontdoorPasswordByClient}
                      type="client"
                      onSelect={handleSelectFrontdoorPassoword}
                    />
                  )}
                  {delivery.frontdoorPasswordByCorp &&
                    delivery.frontdoorPasswordByClient !==
                      delivery.frontdoorPasswordByCorp && (
                      <FrontdoorPasswordItem
                        active={
                          delivery.frontdoorPasswordByCorp ===
                          watch("frontdoorPassword")
                        }
                        frontdoorPassword={delivery.frontdoorPasswordByCorp}
                        type="corp"
                        onSelect={handleSelectFrontdoorPassoword}
                      />
                    )}
                </>
              )}
              <input
                name="frontdoorPassword"
                {...register("frontdoorPassword")}
                hidden
              />
            </div>
          </div>
          <div className="flex justify-center items-center">
            <button
              type="button"
              className="flex-1 p-10"
              onClick={handleCancel}
            >
              취소
            </button>
            <button type="submit" className="text-orange flex-1 p-10">
              확인
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default CreateBaseAddressByFrontdoorPasswordModal;
